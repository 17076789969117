import { DqLoader } from "@decentriq/components";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Stack,
} from "@mui/joy";
import { type ValidationReport } from "validate-js";
import { JsonEditorField } from "components";
import { type LegacyValidationReport } from "features/mediaDataRoom/models";

interface AdvertiserValidationReportDialogProps {
  data?: ValidationReport | LegacyValidationReport;
  loading: boolean;
  onClose: () => void;
  open: boolean;
}

const AdvertiserValidationReportDialog: React.FC<
  AdvertiserValidationReportDialogProps
> = ({ data, loading, open, onClose }) => {
  const formattedValue = JSON.stringify(data?.report ?? "", null, 2);
  return (
    <Modal onClose={onClose} open={open}>
      <ModalDialog>
        <DialogTitle>
          Validation report for Advertiser audience table
        </DialogTitle>
        <Divider />
        <DialogContent>
          {loading ? (
            <Stack alignItems="center" p={3}>
              <DqLoader />
            </Stack>
          ) : (
            <JsonEditorField
              editorOptions={{
                lineNumbers: "off",
                readOnly: true,
                resizable: false,
              }}
              height={400}
              value={formattedValue}
            />
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

AdvertiserValidationReportDialog.displayName =
  "AdvertiserValidationReportDialog";
export default AdvertiserValidationReportDialog;
