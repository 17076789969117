import { useCallback, useState } from "react";
import {
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom/contexts";
import DataNodeItem from "../DataNodeItem";
import PublishedDataNodeContent from "./PublishedDataNodeContent/PublishedDataNodeContent";
import PublisherDataNodeActions from "./PublisherDataNodeActions/PublisherDataNodeActions";

const PublisherDataNode = () => {
  const {
    publishedDatasetsHashes: { hasPublisherData },
  } = useMediaDataRoomInsightsData();
  const { isPublisher } = useMediaDataRoom();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapse = useCallback(
    () => setIsCollapsed((state) => !state),
    [setIsCollapsed]
  );
  const hasUploadingDescription = hasPublisherData && isPublisher;
  return (
    <DataNodeItem
      actions={<PublisherDataNodeActions isProvisioned={hasPublisherData} />}
      content={hasUploadingDescription ? <PublishedDataNodeContent /> : null}
      isCollapsed={isCollapsed}
      title="Publisher data"
      toggleCollapse={hasUploadingDescription ? toggleCollapse : undefined}
    />
  );
};

PublisherDataNode.displayName = "PublisherDataNode";

export default PublisherDataNode;
