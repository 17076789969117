import { DataTargetType } from "@decentriq/graphql/dist/types";

export const dataTargetTypePresentation: Record<DataTargetType, string> = {
  [DataTargetType.S3]: "Amazon S3",
  [DataTargetType.Meta]: "Meta Ads",
  [DataTargetType.GoogleDv_360]: "Google DV 360",
  [DataTargetType.GoogleCloudStorage]: "Google Cloud Storage",
  [DataTargetType.TradeDesk]: "The Trade Desk",
  [DataTargetType.Azure]: "Azure Blob Storage",
  [DataTargetType.Permutive]: "Permutive",
  [DataTargetType.Adform]: "Adform",
  [DataTargetType.GoogleAdManager]: "Google Ad Manager",
  [DataTargetType.Sportradar]: "Sportradar ad:s DSP",
  [DataTargetType.MicrosoftDsp]: "Microsoft Invest DSP",
  [DataTargetType.Splicky]: "Splicky",
  [DataTargetType.GoogleAds]: "Google Ads",
  [DataTargetType.Snapchat]: "Snapchat",
  [DataTargetType.Tiktok]: "Tiktok",
};
