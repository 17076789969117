import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
} from "@mui/joy";
import { memo, useMemo } from "react";
import { JsonEditorField } from "components";
import { useDataLabContext } from "features/dataLabs";
import { type DataLabDataNodeType } from "features/dataLabs/models";

interface DataLabNodeValidationReportDialogProps {
  open: boolean;
  dataNodeType: DataLabDataNodeType | null;
  onClose: () => void;
}

const DataLabNodeValidationReportDialog =
  memo<DataLabNodeValidationReportDialogProps>(
    ({ open, dataNodeType, onClose }) => {
      const { datasetValidationReports } = useDataLabContext();
      const datasetValidationReport = useMemo<string>(
        () =>
          dataNodeType
            ? JSON.stringify(
                datasetValidationReports?.get(dataNodeType)?.report || "",
                null,
                2
              )
            : "",
        [dataNodeType, datasetValidationReports]
      );
      return (
        <Modal open={open}>
          <ModalDialog>
            <DialogTitle>
              Validation report for {dataNodeType} table
            </DialogTitle>
            <Divider />
            <DialogContent>
              <JsonEditorField
                editorOptions={{
                  lineNumbers: "off",
                  readOnly: true,
                  resizable: false,
                }}
                height={400}
                value={datasetValidationReport}
              />
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button onClick={onClose}>Close</Button>
            </DialogActions>
          </ModalDialog>
        </Modal>
      );
    }
  );

DataLabNodeValidationReportDialog.displayName =
  "DataLabNodeValidationReportDialog";

export default DataLabNodeValidationReportDialog;
