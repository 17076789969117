import { faCircleXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Tooltip, Typography } from "@mui/joy";
import { saveAs } from "file-saver";
import { memo, useCallback, useMemo } from "react";
import { useDataLabContext } from "features/dataLabs";
import { type DataLabDataNodeType } from "features/dataLabs/models";
import { DataNodeInvalidRowsInfo } from "features/dataNodes";

interface DataLabNodeDescriptionProps {
  dataNodeType: DataLabDataNodeType;
  datasetName: string | undefined;
}

const DataLabNodeDescription = memo<DataLabNodeDescriptionProps>(
  ({ dataNodeType, datasetName }) => {
    const {
      datasetValidationReports,
      datasetValidationErrors,
      enabledFeatures: { dropInvalidRows },
    } = useDataLabContext();
    const datasetValidationReport = useMemo(
      () => datasetValidationReports.get(dataNodeType)?.report,
      [dataNodeType, datasetValidationReports]
    );
    const handleValidationReportDownload = useCallback(() => {
      const datasetValidationReportBlob = new Blob(
        [JSON.stringify(datasetValidationReport, null, 2)],
        {
          type: "application/json",
        }
      );
      saveAs(
        datasetValidationReportBlob,
        `${datasetName} - validation report.json`
      );
    }, [datasetValidationReport, datasetName]);
    if (datasetValidationErrors.has(dataNodeType)) {
      return (
        <Tooltip
          arrow={true}
          color="danger"
          onClick={(event) => event.stopPropagation()}
          title={
            <Typography>
              The <b>{datasetName}</b> dataset did not pass validation. For
              details, please{" "}
              <u
                onClick={handleValidationReportDownload}
                style={{ cursor: "pointer" }}
              >
                download the report.
              </u>
            </Typography>
          }
        >
          <Typography color="danger">
            <Stack alignItems="center" direction="row">
              <FontAwesomeIcon icon={faCircleXmark} />
              Validation failed
            </Stack>
          </Typography>
        </Tooltip>
      );
    }
    // Indicates number of invalid rows that had been dropped during backend validation
    const numInvalidRowsDropped =
      datasetValidationReport?.dropInvalidRows?.numInvalidRowsDropped || 0;
    if (dropInvalidRows && numInvalidRowsDropped && datasetName) {
      return (
        <DataNodeInvalidRowsInfo
          datasetName={datasetName}
          validationReport={datasetValidationReports.get(dataNodeType)!}
        />
      );
    }
    return null;
  }
);

DataLabNodeDescription.displayName = "DataLabNodeDescription";

export default DataLabNodeDescription;
