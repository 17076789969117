import {
  useEnclaveWorkersQuery,
  useOrganizationEnclaveWorkersQuery,
  useOrganizationLicenseQuery,
  useUpdateOrganizationEnclaveWorkersMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  OrganizationState,
  WorkerTypeShortName,
} from "@decentriq/graphql/dist/types";
import { Checkbox, Chip, FormControl, Stack, Typography } from "@mui/joy";
import { Fragment, memo, useCallback, useMemo } from "react";
import { WORKER_TYPE_LABEL } from "features/computeNode/components/ComputeNodeType/useComputeNodeType";
import { CommonSnackbarOrigin, useGeneralSnackbar, useUserRole } from "hooks";
import { WORKER_TYPE_COLOR } from "models";
import { getEffectiveErrorMessage } from "utils";

interface OrganizationEnclaveWorkersEditorProps {
  organizationId: string;
}

const OrganizationEnclaveWorkersEditor: React.FC<
  OrganizationEnclaveWorkersEditorProps
> = ({ organizationId }) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const { isSuperAdmin } = useUserRole();

  // List of all existing enclave workers
  const { data: enclaveWorkersData } = useEnclaveWorkersQuery({
    skip: !isSuperAdmin,
  });
  const enclaveWorkers = enclaveWorkersData?.workerTypes?.nodes || [];

  // List of enclave workers available to this organization
  const { data: organizationEnclaveWorkersData } =
    useOrganizationEnclaveWorkersQuery({
      variables: { organizationId },
    });
  const availableEnclaveWorkers = useMemo(
    () =>
      organizationEnclaveWorkersData?.organization?.workerTypes?.nodes || [],
    [organizationEnclaveWorkersData]
  );

  const { data: organizationLicenseData } = useOrganizationLicenseQuery({
    variables: { organizationId },
  });
  const organizationLicense = organizationLicenseData?.organization?.state;

  const [updateOrganizationEnclaveWorkersMutation] =
    useUpdateOrganizationEnclaveWorkersMutation();

  const updateOrganizationEnclaveWorkers = useCallback(
    async ({
      target: { value: workerId = "" },
    }: React.ChangeEvent<HTMLInputElement>) => {
      let newWorkersArray = [];
      if (availableEnclaveWorkers.some(({ id }) => id === workerId)) {
        // If selected worker is in availableEnclaveWorkers array - then it should be removed from a list
        newWorkersArray = availableEnclaveWorkers.reduce<string[]>(
          (workersArray, { id }) =>
            id === workerId ? workersArray : [id, ...workersArray],
          []
        );
      } else {
        // Adding new worker to the array otherwise
        const availableEnclaveWorkersIds = availableEnclaveWorkers.map(
          ({ id }) => id
        );
        newWorkersArray = [...availableEnclaveWorkersIds, workerId];
      }
      try {
        await updateOrganizationEnclaveWorkersMutation({
          variables: {
            input: {
              id: organizationId,
              workerTypes: newWorkersArray,
            },
          },
        });
      } catch (error) {
        enqueueSnackbar(
          "Available computations settings could not be updated.",
          {
            context: getEffectiveErrorMessage(error),
            persist: true,
            variant: "error",
          }
        );
      }
    },
    [
      enqueueSnackbar,
      availableEnclaveWorkers,
      organizationId,
      updateOrganizationEnclaveWorkersMutation,
    ]
  );

  const isEditorDisabled = [
    OrganizationState.Passive,
    OrganizationState.Suspended,
  ].includes(organizationLicense!);

  return (
    <FormControl>
      <Typography level="title-md">Available computation types</Typography>
      <Stack
        direction="row"
        flexWrap="wrap"
        sx={{ "& > *": { flex: "0 1 calc(25% - 8px * 3 / 4)" } }}
      >
        {isSuperAdmin
          ? enclaveWorkers.map(({ id, shortName }) => (
              <FormControl key={id}>
                <Checkbox
                  checked={availableEnclaveWorkers.some(
                    (worker) => worker.id === id
                  )}
                  disabled={isEditorDisabled}
                  label={WORKER_TYPE_LABEL[shortName]}
                  onChange={updateOrganizationEnclaveWorkers}
                  value={id}
                />
              </FormControl>
            ))
          : availableEnclaveWorkers.length
            ? availableEnclaveWorkers.map(({ shortName }) =>
                shortName === WorkerTypeShortName.Python ? (
                  <Fragment>
                    <Chip
                      sx={{
                        backgroundColor:
                          WORKER_TYPE_COLOR[
                            shortName as keyof typeof WORKER_TYPE_COLOR
                          ],
                      }}
                    >
                      {shortName}
                    </Chip>
                    <Chip
                      sx={{
                        backgroundColor:
                          WORKER_TYPE_COLOR[
                            WorkerTypeShortName.Match as keyof typeof WORKER_TYPE_COLOR
                          ],
                      }}
                    >
                      Matching
                    </Chip>
                  </Fragment>
                ) : (
                  <Chip
                    sx={{
                      backgroundColor:
                        WORKER_TYPE_COLOR[
                          shortName as keyof typeof WORKER_TYPE_COLOR
                        ],
                    }}
                  >
                    {shortName}
                  </Chip>
                )
              )
            : "—"}
      </Stack>
    </FormControl>
  );
};

OrganizationEnclaveWorkersEditor.displayName =
  "OrganizationEnclaveWorkersEditor";

export default memo(OrganizationEnclaveWorkersEditor);
