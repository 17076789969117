import { type Session } from "@decentriq/core";
import type JSZip from "jszip";
import { type SnackbarKey } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { type ValidationReport } from "validate-js";
import { useMediaDataRoom } from "features/mediaDataRoom/contexts";
import {
  type MediaDataRoomJobHookResult,
  MediaDataRoomJobInput,
  useMediaDataRoomJob,
} from "features/mediaDataRoom/hooks";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  type LegacyValidationReport,
  type PublishedDatasetsHashes,
} from "../../models";

interface AdvertiserDataReportHookPayload {
  dataRoomId: string;
  driverAttestationHash: string;
  publishedDatasetsHashes: PublishedDatasetsHashes;
  session?: Session;
  skip: boolean;
}

const useAdvertiserDataReport = ({
  session,
  dataRoomId,
  driverAttestationHash,
  publishedDatasetsHashes,
  skip,
}: AdvertiserDataReportHookPayload): MediaDataRoomJobHookResult<
  ValidationReport | LegacyValidationReport
> => {
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];
  const {
    supportedFeatures: { enableDropInvalidRows },
  } = useMediaDataRoom();
  const customDatasetsHashes = useMemo(
    () =>
      publishedDatasetsHashes
        // Advertiser data report computation depends only on advertiser dataset so only that used for caching
        // TODO: uncomment when issue with jobs resolved
        //.updateAudiencesDatasetHash(null)
        .updateDatasetsHash(
          publishedDatasetsHashes.advertiserDatasetHash
            ? {
                advertiserDatasetHash:
                  publishedDatasetsHashes.advertiserDatasetHash,
                // TODO: remove this when issue with jobs resolved
                demographicsDatasetHash:
                  publishedDatasetsHashes.publisherDatasetsHashes
                    .demographicsDatasetHash ?? "",
                embeddingsDatasetHash:
                  publishedDatasetsHashes.publisherDatasetsHashes
                    .embeddingsDatasetHash ?? "",
                matchingDatasetHash:
                  publishedDatasetsHashes.publisherDatasetsHashes
                    .matchingDatasetHash ?? "",
                segmentsDatasetHash:
                  publishedDatasetsHashes.publisherDatasetsHashes
                    .segmentsDatasetHash ?? "",
              }
            : null
        ),
    [publishedDatasetsHashes]
  );
  const transform = useCallback(
    async (zip: JSZip): Promise<ValidationReport | LegacyValidationReport> => {
      // In the v2 version of the DCR, the file has been renamed, therefore, a check is applied
      // in order to fetch proper file that actually exists
      const filename = enableDropInvalidRows
        ? "validation-report.json"
        : "report.json";
      const reportFile = zip.file(filename);
      if (reportFile === null) {
        throw new Error(`${reportFile} not found in zip`);
      }
      const validationReport = JSON.parse(await reportFile.async("string")) as
        | ValidationReport
        | { audiences: LegacyValidationReport["report"] };
      return enableDropInvalidRows
        ? (validationReport as ValidationReport)
        : ({
            report: validationReport?.audiences,
            version: "legacy",
          } as LegacyValidationReport);
    },
    [enableDropInvalidRows]
  );
  const computeJob = useMediaDataRoomJob({
    input: MediaDataRoomJobInput.create(
      enableDropInvalidRows
        ? "getAudiencesValidationReport"
        : "ingestAudiencesReport",
      dataRoomId,
      driverAttestationHash,
      customDatasetsHashes
    ),
    requestCreator: useCallback(
      (dataRoomIdHex, scopeIdHex) => ({
        dataRoomIdHex,
        scopeIdHex,
      }),
      []
    ),
    session,
    skip,
    transform,
  });
  useEffect(() => {
    const error = computeJob.error;
    if (error) {
      const key = enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          error,
          "Failed to retrieve Advertiser data validation report"
        )
      );
      setErrorSnackbarId(key);
    } else {
      setErrorSnackbarId((snackbarId) => {
        if (snackbarId) {
          closeSnackbar(snackbarId);
        }
        return undefined;
      });
    }
  }, [closeSnackbar, computeJob.error, enqueueSnackbar, setErrorSnackbarId]);

  return computeJob;
};

export default useAdvertiserDataReport;
