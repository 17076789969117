import {
  useOrganizationFeaturesQuery,
  useOrganizationMicrosoftDspMemberQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  type DataSourceType,
  DataTargetType,
} from "@decentriq/graphql/dist/types";
import { useCallback, useMemo } from "react";
import { useOrganizationPreferences } from "hooks";
import { MicrosoftDspMember } from "models";

const useGetDisabledAndFilteredSyncItems = () => {
  const { organizationId, isLoading: isLoadingOrganizationPreferences } =
    useOrganizationPreferences();
  const { data } = useOrganizationFeaturesQuery({
    skip: isLoadingOrganizationPreferences,
    variables: {
      organizationId,
    },
  });
  const organizationFeatures = data?.organization;

  const { data: organizationMicrosoftDspMemberQueryData } =
    useOrganizationMicrosoftDspMemberQuery({
      skip: !organizationId,
      variables: { organizationId },
    });
  const microsoftDspMember = organizationMicrosoftDspMemberQueryData
    ?.organization?.microsoftDspMember as MicrosoftDspMember;
  const isMicrosoftDspDisabled =
    !microsoftDspMember || microsoftDspMember === MicrosoftDspMember.None;
  const disabledSyncTypes: Record<DataTargetType, boolean> = useMemo(
    () => ({
      [DataTargetType.Adform]:
        organizationFeatures?.adformExportState === "DISABLED",
      [DataTargetType.Azure]:
        organizationFeatures?.azureExportState === "DISABLED",
      [DataTargetType.GoogleAdManager]:
        organizationFeatures?.googleAdManagerExportState === "DISABLED",
      [DataTargetType.GoogleCloudStorage]:
        organizationFeatures?.googleCloudStorageExportState === "DISABLED",
      [DataTargetType.GoogleDv_360]:
        organizationFeatures?.googleDv360ExportState === "DISABLED",
      [DataTargetType.Meta]:
        organizationFeatures?.metaExportState === "DISABLED",
      // TODO follow-up with product about microsoft DSP
      [DataTargetType.MicrosoftDsp]:
        organizationFeatures?.microsoftDspExportState === "DISABLED" ||
        isMicrosoftDspDisabled,
      [DataTargetType.Permutive]:
        organizationFeatures?.permutiveExportState === "DISABLED",
      [DataTargetType.S3]: organizationFeatures?.s3ExportState === "DISABLED",
      [DataTargetType.Splicky]:
        organizationFeatures?.splickyExportState === "DISABLED",
      [DataTargetType.Sportradar]:
        organizationFeatures?.sportradarExportState === "DISABLED",
      [DataTargetType.TradeDesk]:
        organizationFeatures?.tradedeskExportState === "DISABLED",
      [DataTargetType.GoogleAds]:
        organizationFeatures?.googleAdsExportState === "DISABLED",
      [DataTargetType.Snapchat]:
        organizationFeatures?.snapchatExportState === "DISABLED",
      [DataTargetType.Tiktok]:
        organizationFeatures?.tiktokExportState === "DISABLED",
    }),
    [isMicrosoftDspDisabled, organizationFeatures]
  );

  const hiddenSyncTypes: Record<DataTargetType, boolean> = useMemo(
    () => ({
      [DataTargetType.Adform]:
        organizationFeatures?.adformExportState === "HIDDEN",
      [DataTargetType.Azure]:
        organizationFeatures?.azureExportState === "HIDDEN",
      [DataTargetType.GoogleAdManager]:
        organizationFeatures?.googleAdManagerExportState === "HIDDEN",
      [DataTargetType.GoogleCloudStorage]:
        organizationFeatures?.googleCloudStorageExportState === "HIDDEN",
      [DataTargetType.GoogleDv_360]:
        organizationFeatures?.googleDv360ExportState === "HIDDEN",
      [DataTargetType.Meta]: organizationFeatures?.metaExportState === "HIDDEN",
      [DataTargetType.MicrosoftDsp]:
        organizationFeatures?.microsoftDspExportState === "HIDDEN",
      [DataTargetType.Permutive]:
        organizationFeatures?.permutiveExportState === "HIDDEN",
      [DataTargetType.S3]: organizationFeatures?.s3ExportState === "HIDDEN",
      [DataTargetType.Splicky]:
        organizationFeatures?.splickyExportState === "HIDDEN",
      [DataTargetType.Sportradar]:
        organizationFeatures?.sportradarExportState === "HIDDEN",
      [DataTargetType.TradeDesk]:
        organizationFeatures?.tradedeskExportState === "HIDDEN",
      [DataTargetType.GoogleAds]:
        organizationFeatures?.googleAdsExportState === "HIDDEN",
      [DataTargetType.Snapchat]:
        organizationFeatures?.snapchatExportState === "HIDDEN",
      [DataTargetType.Tiktok]:
        organizationFeatures?.tiktokExportState === "HIDDEN",
    }),
    [organizationFeatures]
  );

  const getDisabledItems = useCallback(
    (syncTypes: DataSourceType[] | DataTargetType[]) =>
      syncTypes.filter(
        (syncType) => disabledSyncTypes[syncType as DataTargetType]
      ) as DataTargetType[],
    [disabledSyncTypes]
  );

  const getFilteredItems = useCallback(
    (syncTypes: DataSourceType[] | DataTargetType[]) =>
      syncTypes.filter(
        (syncType) => !hiddenSyncTypes[syncType as DataTargetType]
      ) as DataTargetType[],
    [hiddenSyncTypes]
  );

  return useMemo(
    () => ({
      getDisabledItems,
      getFilteredItems,
    }),
    [getDisabledItems, getFilteredItems]
  );
};

export default useGetDisabledAndFilteredSyncItems;
