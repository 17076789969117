import { Box } from "@mui/joy";
import { DataNodeConstructorMode } from "features/dataNodes";
import DataNodeActions from "features/dataNodes/components/DataNodeConstructor/DataNodeActions";
import { DataNodeConstructorParamsWrapper } from "features/dataNodes/components/DataNodeConstructor/DataNodeConstructorParamsWrapper";

interface DataLabDataNodeActionsProps {
  id: string;
  datasetManifestHash?: string;
  onUpload: () => void;
  isLoading: boolean;
  onDataDeprovision: () => Promise<void>;
  openValidationReport?: () => void;
}

const DataLabDataNodeActions: React.FC<DataLabDataNodeActionsProps> = ({
  id,
  datasetManifestHash,
  onUpload,
  isLoading,
  onDataDeprovision,
  openValidationReport,
}) => {
  return (
    <DataNodeConstructorParamsWrapper mode={DataNodeConstructorMode.ACTION}>
      <Box marginLeft="auto" onClick={(e) => e.stopPropagation()}>
        <DataNodeActions
          dataType="table"
          datasetManifestHash={datasetManifestHash}
          id={id}
          ingestionDestination="dataLab"
          isLoading={isLoading}
          onDeprovision={onDataDeprovision}
          onUpload={onUpload}
          openValidationReport={openValidationReport}
          testing={false}
        />
      </Box>
    </DataNodeConstructorParamsWrapper>
  );
};

DataLabDataNodeActions.displayName = "DataLabDataNodeActions";

export default DataLabDataNodeActions;
