import {
  faArrowUpRightFromSquare as falArrowUpRightFromSquare,
  faBook as falBook,
  faFileLines as falFileLines,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import { useDocsLink } from "hooks";
import Logo from "./assets/logo.svg";

interface WelcomePopupProps {
  open: boolean;
  onClose: () => void;
}

const WelcomePopup = ({ open, onClose }: WelcomePopupProps) => {
  const docsBaseLink = useDocsLink();
  return (
    <Modal open={open}>
      <ModalDialog
        sx={{
          "--ModalDialog-maxWidth": "550px",
          "--ModalDialog-minWidth": "550px",
          py: 3,
        }}
      >
        <DialogTitle>
          <Stack alignItems="center" gap={3} sx={{ margin: "auto" }}>
            <Logo />
            <Typography fontWeight={400} level="h4">
              Welcome to Decentriq!
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack alignItems="center">
            <Typography textAlign="center">
              Collaborate with your partners on your data assets, <br />
              protected by confidential computing.
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ "--Card-padding": 1, paddingTop: 1 }}>
          <Stack
            alignItems="center"
            divider={<Divider />}
            gap={2}
            sx={{ margin: "auto", width: "100%" }}
          >
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" />}
              justifyContent="center"
              sx={{ width: "100%" }}
            >
              <Button
                component="a"
                href={`${docsBaseLink}/welcome-to-decentriq`}
                sx={{ flex: 1, padding: 0 }}
                target="_blank"
              >
                <Typography
                  color="primary"
                  endDecorator={
                    <FontAwesomeIcon icon={falArrowUpRightFromSquare} />
                  }
                  level="body-sm"
                  startDecorator={<FontAwesomeIcon icon={falBook} />}
                  variant="plain"
                >
                  Read documentation
                </Typography>
              </Button>
              <Button
                component="a"
                href="https://www.decentriq.com/end-user-terms"
                sx={{ flex: 1, padding: 0 }}
                target="_blank"
              >
                <Typography
                  color="primary"
                  endDecorator={
                    <FontAwesomeIcon icon={falArrowUpRightFromSquare} />
                  }
                  level="body-sm"
                  startDecorator={<FontAwesomeIcon icon={falFileLines} />}
                  variant="plain"
                >
                  End user terms
                </Typography>
              </Button>
            </Stack>
            <Box>
              <Button color="primary" onClick={onClose} variant="solid">
                Get Started
              </Button>
            </Box>
          </Stack>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default WelcomePopup;
