import { useAuth0 } from "@auth0/auth0-react";
import { useDatasetByHashQuery } from "@decentriq/graphql/dist/hooks";
import { faFileLines, faTable } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip } from "@mui/joy";
import { format, isValid, parseISO } from "date-fns";
import { memo, useState } from "react";
import { useDataRoom } from "contexts";
import { DatasetPopup } from "features/datasets";
import { type DataType } from "models";
import { ellipsisify } from "utils";

interface DataNodeDataButtonProps {
  datasetId: string;
  dataType: DataType;
  datasetManifestHash?: string;
  onClick?: () => void;
  testing?: boolean;
  withActions?: boolean;
}

const DataNodeDataButton = memo<DataNodeDataButtonProps>(
  ({
    datasetId,
    dataType,
    datasetManifestHash,
    onClick,
    testing,
    withActions,
    ...rest
  }) => {
    const { user } = useAuth0();
    const currentUserEmail = user?.email;
    const { data } = useDatasetByHashQuery({
      skip: !datasetManifestHash,
      variables: { manifestHash: datasetManifestHash! },
    });
    const { dataRoomId } = useDataRoom();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const dataset = data?.datasetByManifestHash;
    if (!dataset) {
      return null;
    }
    const { isUploader: isDatasetUploader } = dataset;
    const isSharedDatasetProvisioner =
      !isDatasetUploader &&
      dataset.publications?.nodes?.some(
        (pub) =>
          pub.publisher?.email === currentUserEmail &&
          pub.dataRoom.id === dataRoomId &&
          (pub.leaf.computeNodeId === `${datasetId}_leaf` ||
            pub.leaf.computeNodeId === datasetId)
      );
    const uploaderEmail = isSharedDatasetProvisioner
      ? currentUserEmail
      : dataset.owner.email;
    const formattedDate =
      dataset && isValid(parseISO(dataset.createdAt || ""))
        ? format(parseISO(dataset.createdAt || ""), "MMM d, HH:mm")
        : null;
    const dataIngestionLabel = dataType === "table" ? "dataset" : "file";
    const name = ellipsisify(dataset.name, 23);
    return (
      <>
        <Tooltip
          title={
            <span>
              {`${
                isDatasetUploader || isSharedDatasetProvisioner
                  ? "You"
                  : uploaderEmail
              } provisioned a ${dataIngestionLabel}, uploaded on ${formattedDate}.`}
              <br />
              Click to see more details.
            </span>
          }
        >
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setPopupOpen(true);
              onClick?.();
            }}
            startDecorator={
              <FontAwesomeIcon
                fixedWidth={true}
                icon={dataType === "table" ? faTable : faFileLines}
              />
            }
            sx={{
              justifyContent: "flex-start",
            }}
            {...rest}
          >
            {name}
          </Button>
        </Tooltip>
        <DatasetPopup
          manifestHash={dataset?.manifestHash}
          onDismiss={() => setPopupOpen(false)}
          open={isPopupOpen}
          testing={testing ? { dataNodeId: datasetId } : undefined}
          withActions={withActions}
        />
      </>
    );
  }
);

DataNodeDataButton.displayName = "DataNodeDataButton";

export default DataNodeDataButton;
