// Comes from graphql, but the package does not export javascript enums, only declarations
export enum FeatureVisibilityState {
  Disabled = "DISABLED",
  Hidden = "HIDDEN",
  Enabled = "ENABLED",
}

export enum MicrosoftDspMember {
  Drop8 = "drop8",
  None = "None",
}
