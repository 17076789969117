import { faQuestionCircle as fatQuestionCircle } from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";

interface SupportDialogProps {
  open: boolean;
  onClose: () => void;
}

const SupportDialog: React.FC<SupportDialogProps> = ({ open, onClose }) => {
  return (
    <Modal onClose={onClose} open={open}>
      <ModalDialog role="alertdialog">
        <DialogTitle>
          <FontAwesomeIcon
            fixedWidth={true}
            icon={fatQuestionCircle}
            size="3x"
          />
          Contact technical support
        </DialogTitle>
        <DialogContent>
          <Typography
            level="body-sm"
            sx={{ textWrap: "balance" }}
            textAlign="center"
          >
            For technical support, please contact us at the following address:
          </Typography>
          <Typography level="body-sm" textAlign="center">
            <Link tabIndex={0}>support@decentriq.com</Link>
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default SupportDialog;
