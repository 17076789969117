import { useAuth0 } from "@auth0/auth0-react";
import { usePublishedMediaInsightsDcrOrganizationsQuery } from "@decentriq/graphql/dist/hooks";
import {
  type Organization,
  type PublishedMediaDataRoomQuery,
  type TableColumnFormatType,
  type TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import {
  type ab_media_response as ddcAbMediaResponse,
  type AbMediaComputeLatest,
} from "ddc";
import { createContext, memo, useMemo } from "react";
import { MediaDataRoomJobInput } from "features/mediaDataRoom/hooks";
import { RawSupportedFeatures } from "features/mediaDataRoom/models";
import { isNormalizedEmailIncluded } from "features/mediaDataRoom/utils";
import { useSafeContext } from "hooks";
import { RawMediaDataRoomFeatures } from "models";
import useMediaDataRoomHlDefinition from "./useMediaDataRoomHlDefinition";

export interface MediaDataRoomContextValue {
  dataRoomName: string;
  definition: AbMediaComputeLatest;
  rawDefinition: ddcAbMediaResponse.AbMediaDcr;
  rawSupportedFeatures: string[];
  loading: boolean;
  dataRoomId: string;
  driverAttestationHash: string;
  hasDataPartner: boolean;
  isAdvertiser: boolean;
  isAgency: boolean;
  hasAgency: boolean;
  isDataPartner: boolean;
  isDeactivated: boolean;
  isPublisher: boolean;
  isStopped: boolean;
  isObserver: boolean;
  publisherOrganization:
    | Pick<Organization, "logo" | "__typename" | "id" | "name">
    | undefined;
  advertiserOrganization:
    | Pick<Organization, "logo" | "__typename" | "id" | "name">
    | undefined;
  supportedFeatures: {
    enableAdvertiserAudienceDownload: boolean;
    performanceReportEnabled: boolean;
    showAbsoluteValues: boolean;
    canCreateAudience: boolean;
    enableRemarketing: boolean;
    enableInsights: boolean;
    enableDropInvalidRows: boolean;
    enableLookalike: boolean;
    enableRuleBasedAudiences: boolean;
    canReadAudiences: boolean;
    canReadInsights: boolean;
    canDeleteAudience: boolean;
    canMakeAvailableForPublisher: boolean;
  };
  matchingIdFormat: TableColumnFormatType;
  hashMatchingIdWith: TableColumnHashingAlgorithm | null;
  createdAt: string;
}

export const MediaDataRoomContext =
  createContext<MediaDataRoomContextValue | null>(null);
MediaDataRoomContext.displayName = "MediaDataRoomContext";

export const MediaDataRoomContextConsumer = MediaDataRoomContext.Consumer;

export const useMediaDataRoom = () => useSafeContext(MediaDataRoomContext);

export type MediaDataRoomWrapperProps = React.PropsWithChildren<{
  data: PublishedMediaDataRoomQuery["publishedMediaDataRoom"];
}>;

const MediaDataRoomWrapper = memo<MediaDataRoomWrapperProps>(
  ({
    data: {
      id: dataRoomId,
      name: dataRoomName,
      createdAt,
      driverAttestationHash,
      isStopped,
      deactivatedAt,
    },
    children,
  }) => {
    const { user } = useAuth0();
    const currentUserEmail = user?.email || "";
    const { data: hlDefinition, isLoading: isHlDefinitionLoading } =
      useMediaDataRoomHlDefinition({
        dataRoomId,
        driverAttestationHash,
        queryKey: MediaDataRoomJobInput.create(
          "retrieveDataRoom",
          dataRoomId,
          driverAttestationHash
        )
          .withFetchResultsSubkey()
          .buildQueryKey(),
      });
    const {
      mainAdvertiserEmail,
      mainPublisherEmail,
      publisherEmails: publisherUserEmails = [],
      advertiserEmails: advertiserUserEmails = [],
      agencyEmails: agencyUserEmails = [],
      observerEmails: observerUserEmails = [],
      dataPartnerEmails: dataPartnerUserEmails = [],
      hashMatchingIdWith,
      matchingIdFormat,
    } = hlDefinition?.definition ?? {};
    const {
      hasDataPartner,
      isAdvertiser,
      isAgency,
      hasAgency,
      isDataPartner,
      isObserver,
      isPublisher,
    } = useMemo(
      () => ({
        hasAgency: agencyUserEmails.length > 0,
        hasDataPartner:
          dataPartnerUserEmails !== null && dataPartnerUserEmails.length > 0,
        isAdvertiser: isNormalizedEmailIncluded(
          advertiserUserEmails,
          currentUserEmail
        ),
        isAgency: isNormalizedEmailIncluded(agencyUserEmails, currentUserEmail),
        isDataPartner: isNormalizedEmailIncluded(
          dataPartnerUserEmails || [],
          currentUserEmail
        ),
        isObserver: isNormalizedEmailIncluded(
          observerUserEmails,
          currentUserEmail
        ),
        isPublisher: isNormalizedEmailIncluded(
          publisherUserEmails,
          currentUserEmail
        ),
      }),
      [
        advertiserUserEmails,
        agencyUserEmails,
        currentUserEmail,
        dataPartnerUserEmails,
        observerUserEmails,
        publisherUserEmails,
      ]
    );
    const { data: organizationsData, loading: isOrganisationsDataLoading } =
      usePublishedMediaInsightsDcrOrganizationsQuery({
        skip: !mainAdvertiserEmail || !mainPublisherEmail,
        variables: {
          advertiserEmail: mainAdvertiserEmail!,
          publisherEmail: mainPublisherEmail!,
        },
      });
    const contextValue = useMemo((): MediaDataRoomContextValue => {
      const hlSupportedFeatures = hlDefinition?.supportedFeatures ?? [];
      const enableLookalike = hlSupportedFeatures.includes(
        RawMediaDataRoomFeatures.Lookalike
      );
      const enableRuleBasedAudiences = hlSupportedFeatures.includes(
        RawMediaDataRoomFeatures.RuleBasedAudiences
      );
      const enableRemarketing = hlSupportedFeatures.includes(
        RawMediaDataRoomFeatures.Remarketing
      );
      const hideAbsoluteValues = hlSupportedFeatures.includes(
        RawSupportedFeatures.ENABLE_HIDE_ABSOLUTE_AUDIENCE_SIZES
      );
      const enableInsights = hlSupportedFeatures.includes(
        RawMediaDataRoomFeatures.Insights
      );
      const enableDropInvalidRows = hlSupportedFeatures.includes(
        RawMediaDataRoomFeatures.DropInvalidRows
      );
      return {
        advertiserOrganization: {
          ...organizationsData?.advertiserOrganization?.organization!,
          logo: organizationsData?.advertiserOrganization?.organizationLogo,
        },
        createdAt,
        dataRoomId,
        dataRoomName,
        definition: hlDefinition?.definition!,
        driverAttestationHash,
        hasAgency,
        hasDataPartner,
        hashMatchingIdWith: (hashMatchingIdWith ||
          null) as TableColumnHashingAlgorithm | null,
        isAdvertiser,
        isAgency,
        isDataPartner,
        isDeactivated: Boolean(deactivatedAt),
        isObserver,
        isPublisher,
        isStopped,
        loading: isHlDefinitionLoading || isOrganisationsDataLoading,
        matchingIdFormat: matchingIdFormat as TableColumnFormatType,
        publisherOrganization: {
          ...organizationsData?.publisherOrganization?.organization!,
          logo: organizationsData?.publisherOrganization?.organizationLogo,
        },
        rawDefinition: hlDefinition?.rawDefinition!,
        rawSupportedFeatures: hlDefinition?.supportedFeatures!,
        supportedFeatures: {
          canCreateAudience:
            (enableLookalike || enableRuleBasedAudiences) &&
            (isAdvertiser || isAgency),
          canDeleteAudience: isAdvertiser || isAgency,
          canMakeAvailableForPublisher: isAdvertiser || isAgency,
          canReadAudiences:
            (enableLookalike ||
              enableRemarketing ||
              enableRuleBasedAudiences) &&
            (isObserver || isPublisher || isAdvertiser || isAgency),
          canReadInsights:
            enableInsights &&
            (isObserver || isPublisher || isAdvertiser || isAgency),
          enableAdvertiserAudienceDownload: hlSupportedFeatures.includes(
            RawSupportedFeatures.ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD
          ),
          enableDropInvalidRows,
          enableInsights,
          enableLookalike,
          enableRemarketing,
          enableRuleBasedAudiences,
          performanceReportEnabled: hlSupportedFeatures.includes(
            RawSupportedFeatures.ENABLE_MODEL_PERFORMANCE_EVALUATION
          ),
          showAbsoluteValues: !hideAbsoluteValues,
        },
      };
    }, [
      organizationsData?.advertiserOrganization?.organization,
      organizationsData?.advertiserOrganization?.organizationLogo,
      organizationsData?.publisherOrganization?.organization,
      organizationsData?.publisherOrganization?.organizationLogo,
      dataRoomId,
      dataRoomName,
      hlDefinition?.definition,
      hlDefinition?.supportedFeatures,
      hlDefinition?.rawDefinition,
      driverAttestationHash,
      hasAgency,
      hasDataPartner,
      hashMatchingIdWith,
      isAdvertiser,
      isAgency,
      isDataPartner,
      deactivatedAt,
      isObserver,
      isPublisher,
      isStopped,
      isHlDefinitionLoading,
      isOrganisationsDataLoading,
      matchingIdFormat,
      createdAt,
    ]);
    return (
      <MediaDataRoomContext.Provider value={contextValue}>
        {children}
      </MediaDataRoomContext.Provider>
    );
  }
);

MediaDataRoomWrapper.displayName = "MediaDataRoomWrapper";

export default MediaDataRoomWrapper;
