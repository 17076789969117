import { type ApolloError } from "@apollo/client";
import {
  DqSortableAccordion,
  DqSortableAccordionDetails,
  DqSortableAccordionGroup,
  DqSortableAccordionSummary,
} from "@decentriq/components";
import { useSetDataNodesOrderMutation } from "@decentriq/graphql/dist/hooks";
import { testIds } from "@decentriq/utils";
import {
  Accordion,
  AccordionDetails,
  accordionDetailsClasses,
  AccordionGroup,
  AccordionSummary,
  Alert,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/joy";
import { useSelections } from "ahooks";
import { Fragment, memo, useCallback, useMemo } from "react";
import { DatasetConstraints } from "features/computeNode";
import { useDataNodeActions } from "features/dataNodes/containers/DataNodes/DataNodesActionsWrapper";
import { DataNodeConstructorMode } from "features/dataNodes/models";
import { useIsDataRoomOwner } from "hooks";
import { type HooksNode } from "hooks/useNodes/useNodes";
import { type DataRoomData, type DataRoomDataTable } from "models";
import { useUniqueness } from "../../hooks";
import { type DataNodeChangeOutcomeType } from "../DataNodeWithTestDataChangedDialog/DataNodeWithTestDataChangedDialog";
import DataNodeAddNewField from "./DataNodeAddNewField";
import DataNodeChangeOutcomeHandler, {
  useDataNodeChangeOutcomeHandler,
} from "./DataNodeChangeOutcomeHandler";
import { type DataRoomDataNodeActionsRenderer } from "./DataNodeConstructorModels";
import {
  DataNodeConstructorParamsConsumer,
  DataNodeConstructorParamsWrapper,
  useDataNodeConstructorParams,
} from "./DataNodeConstructorParamsWrapper";
import DataNodeEditableTile from "./DataNodeEditableTile";
import DataNodeTile from "./DataNodeTile";
import { TableNodeColumnConstructor } from "./TableNodeColumnConstructor";

export * from "./DataNodeConstructorModels";

interface SharedProps {
  renderDataActions?: DataRoomDataNodeActionsRenderer;
  toggle: (id: string) => void;
}

interface DataNodeItemProps extends SharedProps {
  dataNode: DataRoomData;
  isSelected: (id: string) => boolean;
  isExpanded: boolean;
  otherNodeNames: string[];
}

interface DataNodeListProps extends SharedProps {
  dataNodes: DataRoomData[];
  dataNodesOrder: string[];
  dataRoomId: string;
  isSelected: (id: string) => boolean;
  nodes: HooksNode[];
}

const getDuplicatedNodeName = (
  nodeName: string,
  nodeNames: string[]
): string => {
  const nextName = `Copy of ${nodeName}`;
  if (!nodeNames.includes(nextName)) {
    return nextName;
  }
  return getDuplicatedNodeName(nextName, nodeNames);
};

const DataNodeItem = memo(
  ({
    dataNode,
    isExpanded,
    isSelected,
    renderDataActions,
    otherNodeNames,
    toggle,
  }: DataNodeItemProps) => {
    const { id, isRequired } = dataNode;
    const { handleChangeOutcome } = useDataNodeChangeOutcomeHandler();
    const { readOnly } = useDataNodeConstructorParams();
    const { uniqueColumnIds, updateUniqueColumnIds, isLoading } =
      useUniqueness(id);
    const isDraggable = !readOnly;
    const [ThisAccordion, ThisAccordionSummary, ThisAccordionSummaryDetails] =
      isDraggable
        ? [
            DqSortableAccordion,
            DqSortableAccordionSummary,
            DqSortableAccordionDetails,
          ]
        : [Accordion, AccordionSummary, AccordionDetails];
    const { handleDuplicate } = useDataNodeActions();

    const handleDuplicateWithAutoExpand = useCallback(async () => {
      const newNode = await handleDuplicate({
        ...dataNode,
        name: getDuplicatedNodeName(dataNode.name, otherNodeNames),
      });
      const newNodeId = newNode?.data?.dataNode.create.id;
      if (!newNodeId || !isSelected(dataNode.id)) {
        return;
      }
      toggle(newNodeId);
    }, [dataNode, handleDuplicate, isSelected, otherNodeNames, toggle]);

    return (
      <Fragment>
        <ThisAccordion
          expanded={isExpanded}
          id={id}
          onChange={() => toggle(id)}
          variant="outlined"
        >
          <ThisAccordionSummary
            data-testid={testIds.dataNode.dataNodeCreator.toggleEditor}
            id={id}
            slotProps={{
              indicator: { sx: { order: 1, ...(!isDraggable && { ml: 0.5 }) } },
            }}
          >
            {readOnly ? (
              <DataNodeTile
                dataNode={dataNode}
                renderDataActions={renderDataActions}
              />
            ) : (
              <DataNodeEditableTile
                dataNode={dataNode}
                dataTestid={testIds.dataNode.dataNodeEditableTile.nameInput}
                hasError={false} // TODO: pass actual error
                helperTestid={
                  testIds.dataNode.dataNodeEditableTile.nameInputHelper
                }
                isLoading={false}
                onChangeOutcome={() =>
                  handleChangeOutcome("deleted", { dataNode })
                }
                onDuplicate={handleDuplicateWithAutoExpand}
                otherNodeNames={otherNodeNames}
                renderDataActions={renderDataActions}
              />
            )}
          </ThisAccordionSummary>
          <ThisAccordionSummaryDetails
            slotProps={{
              content: {
                sx: {
                  [`&.${accordionDetailsClasses.expanded}`]: {
                    paddingBlock: "0.5rem !important",
                  },
                  p: 1,
                },
              },
              root: {
                sx: {
                  borderBottomLeftRadius: "var(--List-radius)",
                  borderBottomRightRadius: "var(--List-radius)",
                },
              },
            }}
          >
            <Stack>
              {dataNode.dataType === "table" && (
                <TableNodeColumnConstructor
                  columns={(dataNode as DataRoomDataTable).columns}
                  columnsOrder={(dataNode as DataRoomDataTable).columnsOrder}
                  isLoading={isLoading}
                  onChangeOutcome={(columnAdded, columnId) =>
                    handleChangeOutcome(
                      (columnAdded
                        ? "columnAdded"
                        : "changed") as DataNodeChangeOutcomeType,
                      { columnId, dataNode }
                    )
                  }
                  tableNodeId={id}
                  uniqueColumnIds={uniqueColumnIds}
                  updateUniqueColumnIds={updateUniqueColumnIds}
                />
              )}
              <DatasetConstraints
                dataType={dataNode.dataType}
                disabled={false}
                id={id}
                readOnly={readOnly}
                value={isRequired}
              />
              {dataNode.dataType === "unstructured" ? (
                <Typography level="body-md">
                  Unstructured data files of any kind can be provisioned (e.g.
                  JSON, ZIP, TXT)
                </Typography>
              ) : null}
            </Stack>
          </ThisAccordionSummaryDetails>
        </ThisAccordion>
      </Fragment>
    );
  }
);
DataNodeItem.displayName = "DataNodeItem";

const DataNodeList = memo(
  ({
    dataNodes,
    dataNodesOrder,
    dataRoomId,
    isSelected,
    nodes,
    renderDataActions,
    toggle,
  }: DataNodeListProps) => {
    const dataNodeIds = useMemo(
      () =>
        dataNodes
          .slice()
          .sort(
            (a, b) =>
              dataNodesOrder.indexOf(a.id) - dataNodesOrder.indexOf(b.id)
          )
          .map((dataNode) => dataNode.id),
      [dataNodes, dataNodesOrder]
    );
    const [setDataRoomDataNodesOrder] = useSetDataNodesOrderMutation();
    const ids = dataNodeIds;
    const reorderDataNodes = useCallback(
      (dataNodesOrder: string[]) => {
        void setDataRoomDataNodesOrder({
          optimisticResponse: {
            __typename: "Mutation",
            draftDataRoom: {
              __typename: "DraftDataRoomMutations",
              setDataNodesOrder: {
                __typename: "DraftDataRoom",
                dataNodesOrder,
                id: dataRoomId,
              },
            },
          },
          variables: {
            dataNodesOrder,
            dataRoomId,
          },
        });
      },
      [dataRoomId, setDataRoomDataNodesOrder]
    );
    const { readOnly } = useDataNodeConstructorParams();
    const isOwner = useIsDataRoomOwner();
    const isSortable = isOwner && !readOnly;
    const dataNodesList = useMemo(
      () =>
        ids.map((id: string) => {
          const dataNode = dataNodes.find(
            ({ id: dataNodeId }) => dataNodeId === id
          );
          const otherNodeNames = nodes
            .filter(({ id: dataNodeId }) => dataNodeId !== id)
            .map(({ name }) => name);
          return dataNode ? (
            <DataNodeItem
              dataNode={dataNode}
              isExpanded={isSelected(dataNode.id)}
              isSelected={isSelected}
              key={id}
              otherNodeNames={otherNodeNames}
              renderDataActions={renderDataActions}
              toggle={toggle}
            />
          ) : null;
        }),
      [dataNodes, ids, isSelected, nodes, renderDataActions, toggle]
    );
    return isSortable ? (
      <DqSortableAccordionGroup
        ids={ids}
        onIdsSort={reorderDataNodes}
        sx={{
          [`& .${accordionDetailsClasses.content}`]: {
            boxShadow: (theme) => `inset 0 1px ${theme.vars.palette.divider}`,
            [`&.${accordionDetailsClasses.expanded}`]: {
              paddingBlock: "0.75rem",
            },
          },
        }}
      >
        {dataNodesList}
      </DqSortableAccordionGroup>
    ) : (
      <AccordionGroup
        className="separated"
        sx={{
          [`& .${accordionDetailsClasses.content}`]: {
            boxShadow: (theme) => `inset 0 1px ${theme.vars.palette.divider}`,
            [`&.${accordionDetailsClasses.expanded}`]: {
              paddingBlock: "0.75rem",
            },
          },
        }}
      >
        {dataNodesList}
      </AccordionGroup>
    );
  }
);
DataNodeList.displayName = "DataNodeList";

const emptyConstructorStatusMap = new Map<DataNodeConstructorMode, string>([
  [
    DataNodeConstructorMode.READONLY,
    "This data clean room doesn't have any data nodes declared.",
  ],
  [
    DataNodeConstructorMode.STATUS,
    "This data clean room doesn't have any data nodes declared.",
  ],
  [DataNodeConstructorMode.ACTION, "There is no data available to you."],
  [
    DataNodeConstructorMode.DEGRADE_ACTION,
    "You are not allowed to delete data.",
  ],
]);

interface DataNodeConstructorProps {
  dataNodes: DataRoomData[];
  dataNodesOrder: string[];
  dataRoomId: string;
  error?: ApolloError;
  loading?: boolean;
  mode: DataNodeConstructorMode;
  nodes: HooksNode[];
  renderDataActions?: DataRoomDataNodeActionsRenderer;
}

const DataNodeConstructor: React.FC<DataNodeConstructorProps> = memo(
  ({
    dataNodes,
    dataNodesOrder,
    dataRoomId,
    error,
    loading,
    mode,
    nodes,
    renderDataActions,
  }) => {
    const indexes = useMemo(() => dataNodes.map((t) => t.id), [dataNodes]);
    const { isSelected, toggle } = useSelections(indexes, []);
    const computeNodeNamesAndTypes = useMemo(() => {
      return dataNodes.map(({ name, dataType }) => ({
        dataType,
        name,
      }));
    }, [dataNodes]);
    if (loading && !dataNodes.length) {
      return (
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          padding="1rem"
        >
          <CircularProgress sx={{ "--CircularProgress-size": "1.5rem" }} />
        </Box>
      );
    }
    if (error) {
      return (
        <Alert color="danger">
          Data clean room data nodes could not be retrieved. Please try again by
          refreshing the page.
        </Alert>
      );
    }
    return (
      <DataNodeConstructorParamsWrapper mode={mode}>
        <Stack>
          <DataNodeConstructorParamsConsumer>
            {({ readOnly }) =>
              readOnly ? undefined : (
                <Stack direction="row">
                  <DataNodeAddNewField
                    computeNodeNamesAndTypes={computeNodeNamesAndTypes}
                    toggle={toggle}
                  />
                </Stack>
              )
            }
          </DataNodeConstructorParamsConsumer>
          <DataNodeChangeOutcomeHandler>
            {dataNodes.length > 0 && (
              <DataNodeList
                dataNodes={dataNodes}
                dataNodesOrder={dataNodesOrder}
                dataRoomId={dataRoomId}
                isSelected={isSelected}
                nodes={nodes}
                renderDataActions={renderDataActions}
                toggle={toggle}
              />
            )}
          </DataNodeChangeOutcomeHandler>
          {!dataNodes.length && emptyConstructorStatusMap.get(mode) ? (
            <Typography level="body-md" sx={{ mb: 1 }}>
              {emptyConstructorStatusMap.get(mode)}
            </Typography>
          ) : undefined}
        </Stack>
      </DataNodeConstructorParamsWrapper>
    );
  }
);
DataNodeConstructor.displayName = "DataNodeConstructor";

export default DataNodeConstructor;
