import {
  DqDatasetSyncInfo,
  DqDatasetSyncsList,
  type LocalDataSourceType,
  SyncDirectionType,
} from "@decentriq/components";
import { DataSourceType } from "@decentriq/graphql/dist/types";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ModalDialog,
} from "@mui/joy";
import { type Dispatch, memo, type SetStateAction, useState } from "react";
import { useWizard } from "react-use-wizard";
import useGetDisabledAndFilteredSyncItems from "./useGetDisabledAndFilteredSyncItems";

interface SelectSyncStepProps {
  onClose?: () => void;
  onSelect: Dispatch<
    SetStateAction<[DataSourceType | null, LocalDataSourceType | null]>
  >;
}

const SelectSyncStep: React.FC<SelectSyncStepProps> = memo(
  ({ onClose, onSelect }) => {
    const { handleStep, nextStep } = useWizard();
    const [sync, setSync] = useState<
      [DataSourceType | null, LocalDataSourceType | null]
    >([null, null]);
    const [syncType] = sync || [];
    handleStep(() => {
      onSelect(sync);
    });

    const { getDisabledItems, getFilteredItems } =
      useGetDisabledAndFilteredSyncItems();

    return (
      <ModalDialog>
        <DialogTitle>Import dataset</DialogTitle>
        <Divider />
        <DialogContent>
          <DqDatasetSyncsList
            getDisabledItems={getDisabledItems}
            getFilteredItems={getFilteredItems}
            onChange={(value) => {
              setSync(value as [DataSourceType, LocalDataSourceType]);
            }}
            syncDirection={SyncDirectionType.IMPORT}
            value={sync}
          />
        </DialogContent>
        <Divider />
        {syncType && syncType !== DataSourceType.Local ? (
          <DqDatasetSyncInfo
            syncDirection={SyncDirectionType.IMPORT}
            syncType={syncType}
          />
        ) : null}
        <Divider />
        <DialogActions>
          <Button onClick={() => onClose?.()}>Cancel</Button>
          <Button
            color="primary"
            disabled={!syncType}
            onClick={nextStep}
            variant="solid"
          >
            Continue
          </Button>
        </DialogActions>
      </ModalDialog>
    );
  }
);
SelectSyncStep.displayName = "SelectSyncStep";

export default SelectSyncStep;
