import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Typography,
} from "@mui/joy";
import { memo } from "react";

interface DataNodeItemProps {
  title: string;
  toggleCollapse?: () => void;
  isCollapsed: boolean;
  actions: React.ReactNode;
  content: React.ReactNode;
  description?: React.ReactNode;
}

const DataNodeItem = memo<DataNodeItemProps>(
  ({ description, title, toggleCollapse, actions, content, ...restProps }) => {
    const collapsible: boolean = Boolean(toggleCollapse);
    const isCollapsed = collapsible && restProps.isCollapsed;
    return collapsible ? (
      <AccordionGroup>
        <Accordion
          expanded={!isCollapsed}
          onChange={toggleCollapse}
          variant="outlined"
        >
          <AccordionSummary
            slotProps={{ indicator: { sx: { ml: 0.5, order: -1 } } }}
          >
            <Typography
              alignItems="center"
              display="inline-flex"
              level="title-md"
              sx={{ flex: 1 }}
            >
              {title}
              {description && (
                <Typography component="span" level="body-sm" sx={{ ml: 2 }}>
                  {description}
                </Typography>
              )}
            </Typography>
            {actions}
          </AccordionSummary>
          <AccordionDetails>{content}</AccordionDetails>
        </Accordion>
      </AccordionGroup>
    ) : (
      <AccordionGroup>
        <Accordion expanded={Boolean(content)} variant="outlined">
          <AccordionSummary
            slotProps={{
              button: {
                sx: {
                  // This one disables the hover effect on all the items, which can not be expanded or collapsed
                  [`:hover:not(.Mui-selected, [aria-selected="true"])`]: {
                    backgroundColor: "transparent",
                    cursor: "inherit",
                  },
                  pl: 1.5,
                },
              },
              indicator: { sx: { display: "none", ml: 0.5, order: -1 } },
            }}
          >
            <Typography
              alignItems="center"
              display="inline-flex"
              level="title-md"
              sx={{ flex: 1 }}
            >
              {title}
              {description && (
                <Typography component="span" level="body-sm" sx={{ ml: 2 }}>
                  {description}
                </Typography>
              )}
            </Typography>
            {actions}
          </AccordionSummary>
          {content ? <AccordionDetails>{content}</AccordionDetails> : null}
        </Accordion>
      </AccordionGroup>
    );
  }
);

export default DataNodeItem;
