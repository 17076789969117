import {
  useDataConnectorJobQuery,
  useDataConnectorJobResultQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  type DataConnectorJobResult,
  DataImportExportStatus,
} from "@decentriq/graphql/dist/types";

interface DatasetImportResultHookResult {
  status: DataImportExportStatus | undefined;
  result: DataConnectorJobResult | undefined | null;
}

export const useDatasetImportResult = (
  datasetImportId: string | null
): DatasetImportResultHookResult => {
  const { data: datasetImportData } = useDataConnectorJobQuery({
    skip: !datasetImportId,
    variables: { id: datasetImportId! },
  });
  const {
    dataConnectorJob: {
      status: datasetImportStatus = DataImportExportStatus.Pending,
    } = {},
  } = datasetImportData || {};
  const { data } = useDataConnectorJobResultQuery({
    skip: !datasetImportId,
    variables: { id: datasetImportId! },
  });
  const result = data?.dataConnectorJob?.result;
  return {
    result,
    status: datasetImportStatus,
  };
};
