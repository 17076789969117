import { useOrganizationFeaturesQuery } from "@decentriq/graphql/dist/hooks";
import {
  DataSourceType,
  type DataTargetType,
} from "@decentriq/graphql/dist/types";
import { useCallback, useMemo } from "react";
import { useOrganizationPreferences } from "hooks";

const useGetDisabledAndFilteredSyncItems = () => {
  const { organizationId, isLoading: isLoadingOrganizationPreferences } =
    useOrganizationPreferences();

  const { data } = useOrganizationFeaturesQuery({
    skip: isLoadingOrganizationPreferences,
    variables: {
      organizationId,
    },
  });
  const organizationFeatures = data?.organization;

  const disabledSyncTypes: Record<DataSourceType, boolean> = useMemo(
    () => ({
      [DataSourceType.Azure]:
        organizationFeatures?.azureImportState === "DISABLED",
      [DataSourceType.Compute]:
        organizationFeatures?.computeImportState === "DISABLED",
      [DataSourceType.GoogleCloudStorage]:
        organizationFeatures?.googleCloudStorageImportState === "DISABLED",
      [DataSourceType.Local]:
        organizationFeatures?.localImportState === "DISABLED",
      [DataSourceType.Permutive]:
        organizationFeatures?.permutiveImportState === "DISABLED",
      [DataSourceType.S3]: organizationFeatures?.s3ImportState === "DISABLED",
      [DataSourceType.Salesforce]:
        organizationFeatures?.salesforceImportState === "DISABLED",
      [DataSourceType.Snowflake]:
        organizationFeatures?.snowflakeImportState === "DISABLED",
      [DataSourceType.Databricks]:
        organizationFeatures?.databricksImportState === "DISABLED",
    }),
    [organizationFeatures]
  );

  const hiddenSyncTypes: Record<DataSourceType, boolean> = useMemo(
    () => ({
      [DataSourceType.Azure]:
        organizationFeatures?.azureImportState === "HIDDEN",
      [DataSourceType.Compute]:
        organizationFeatures?.computeImportState === "HIDDEN",
      [DataSourceType.GoogleCloudStorage]:
        organizationFeatures?.googleCloudStorageImportState === "HIDDEN",
      [DataSourceType.Local]:
        organizationFeatures?.localImportState === "HIDDEN",
      [DataSourceType.Permutive]:
        organizationFeatures?.permutiveImportState === "HIDDEN",
      [DataSourceType.S3]: organizationFeatures?.s3ImportState === "HIDDEN",
      [DataSourceType.Salesforce]:
        organizationFeatures?.salesforceImportState === "HIDDEN",
      [DataSourceType.Snowflake]:
        organizationFeatures?.snowflakeImportState === "HIDDEN",
      [DataSourceType.Databricks]:
        organizationFeatures?.databricksImportState === "HIDDEN",
    }),
    [organizationFeatures]
  );
  const getDisabledItems = useCallback(
    (syncTypes: DataSourceType[] | DataTargetType[]) =>
      syncTypes.filter(
        (syncType) => disabledSyncTypes[syncType as DataSourceType]
      ) as DataSourceType[],
    [disabledSyncTypes]
  );

  const getFilteredItems = useCallback(
    (syncTypes: DataSourceType[] | DataTargetType[]) =>
      syncTypes.filter(
        (syncType) => !hiddenSyncTypes[syncType as DataSourceType]
      ) as DataSourceType[],
    [hiddenSyncTypes]
  );

  return useMemo(
    () => ({
      getDisabledItems,
      getFilteredItems,
    }),
    [getDisabledItems, getFilteredItems]
  );
};

export default useGetDisabledAndFilteredSyncItems;
