import {
  faEllipsisV as falEllipsisV,
  faFileExport as falFileExport,
  faTrash as falTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ButtonGroup,
  Dropdown,
  IconButton,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Tooltip,
} from "@mui/joy";
import { memo, useCallback, useState } from "react";
import {
  useAdvertiserAudiences,
  useMediaDataRoom,
} from "features/mediaDataRoom/contexts";
import { type Audience } from "features/mediaDataRoom/models";
import DeleteAudienceButton from "../DeleteAudienceButton/DeleteAudienceButton";

interface ActionsMenuProps {
  audience: Audience;
  mode?: "icons" | "dropdown";
}

const ActionsMenu = memo<ActionsMenuProps>(({ audience, mode = "icons" }) => {
  const { publishAudience, isPublishingAudience } = useAdvertiserAudiences();
  const [isPublishingCurrentAudience, setIsPublishingCurrentAudience] =
    useState<boolean>(false);
  const {
    supportedFeatures: { canDeleteAudience, canMakeAvailableForPublisher },
  } = useMediaDataRoom();
  const publish = useCallback(async () => {
    setIsPublishingCurrentAudience(true);
    await publishAudience(audience);
    setIsPublishingCurrentAudience(false);
  }, [publishAudience, audience]);
  return mode === "icons" ? (
    <ButtonGroup
      sx={{
        "& > .MuiIconButton-root:disabled": {
          "--ButtonGroup-separatorColor": "neutral",
        },
        "--ButtonGroup-separatorColor": "neutral",
      }}
      variant="plain"
    >
      {canMakeAvailableForPublisher && (
        <Tooltip title="Grant publisher access">
          <IconButton
            disabled={
              audience.mutable.status === "published" || isPublishingAudience
            }
            loading={isPublishingCurrentAudience}
            onClick={publish}
          >
            <FontAwesomeIcon icon={falFileExport} />
          </IconButton>
        </Tooltip>
      )}
      {canDeleteAudience && (
        <DeleteAudienceButton
          audience={audience}
          renderTrigger={(openDeleteAudienceDialog) => (
            <Tooltip title="Delete audience">
              <IconButton onClick={openDeleteAudienceDialog}>
                <FontAwesomeIcon icon={falTrash} />
              </IconButton>
            </Tooltip>
          )}
        />
      )}
    </ButtonGroup>
  ) : (
    <>
      <Dropdown>
        <MenuButton
          endDecorator={null}
          slotProps={{
            root: { color: "neutral" },
          }}
          slots={{ root: IconButton }}
        >
          <FontAwesomeIcon icon={falEllipsisV} />
        </MenuButton>
        <Menu keepMounted={true} placement="bottom-end">
          {canMakeAvailableForPublisher &&
          audience.mutable.status !== "published" ? (
            <MenuItem disabled={isPublishingAudience} onClick={publish}>
              <ListItemDecorator>
                <FontAwesomeIcon icon={falFileExport} />
              </ListItemDecorator>
              Grant publisher access
            </MenuItem>
          ) : null}
          {canDeleteAudience && (
            <DeleteAudienceButton
              audience={audience}
              renderTrigger={(openDeleteAudienceDialog) => (
                <MenuItem onClick={openDeleteAudienceDialog}>
                  <ListItemDecorator>
                    <FontAwesomeIcon icon={falTrash} />
                  </ListItemDecorator>
                  Delete audience
                </MenuItem>
              )}
            />
          )}
        </Menu>
      </Dropdown>
    </>
  );
});
ActionsMenu.displayName = "ActionsMenu";

export default ActionsMenu;
