import type {
  DataSourceType,
  DataTargetType,
  UpdateOrganizationFlagsInput,
} from "@decentriq/graphql/dist/types";
import { faBan, faCheck, faEye } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  FormControl,
  FormLabel,
  Option,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/joy";
import { Fragment, memo } from "react";
import {
  dataSourceTypePresentation,
  dataTargetTypePresentation,
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
} from "features/datasets";
import { FeatureVisibilityState } from "models";

type FlagName = keyof Pick<
  UpdateOrganizationFlagsInput,
  | "s3ExportState"
  | "metaExportState"
  | "googleDv360ExportState"
  | "tradedeskExportState"
  | "azureExportState"
  | "googleCloudStorageExportState"
  | "googleAdManagerExportState"
  | "permutiveExportState"
  | "sportradarExportState"
  | "adformExportState"
  | "microsoftDspExportState"
  | "splickyExportState"
  | "googleAdsExportState"
  | "tiktokExportState"
  | "snapchatExportState"
  | "localImportState"
  | "computeImportState"
  | "s3ImportState"
  | "snowflakeImportState"
  | "salesforceImportState"
  | "azureImportState"
  | "googleCloudStorageImportState"
  | "permutiveImportState"
  | "databricksImportState"
>;
interface ConnectorStateSelectProps {
  connectionType: DataSourceType | DataTargetType;
  name: FlagName;
  state: FeatureVisibilityState;
  disabled?: boolean;
  disableEnableOption?: boolean;
  disableEnableOptionMessage?: string;
  onChange: (connectorName: FlagName, state: FeatureVisibilityState) => void;
}

const dataConnectorTypePresentation = {
  ...dataSourceTypePresentation,
  ...dataTargetTypePresentation,
};

const ConnectorStateSelect = memo<ConnectorStateSelectProps>(
  ({
    connectionType,
    name,
    state,
    onChange,
    disableEnableOption,
    disabled,
    disableEnableOptionMessage,
  }) => {
    return (
      <FormControl>
        <Stack spacing={0.5}>
          <FormLabel>
            <Box sx={{ marginRight: 1 }}>
              <ExternalConnectionsIcon
                connectionType={connectionType}
                size={ExternalConnectionsIconSize.xs}
              />
            </Box>
            {dataConnectorTypePresentation[connectionType]}
          </FormLabel>
          <Select
            disabled={disabled}
            onChange={(_event, value) => {
              onChange(name, value as FeatureVisibilityState);
            }}
            renderValue={(option) => {
              switch (option?.value) {
                case FeatureVisibilityState.Disabled:
                  return (
                    <Fragment>
                      <FontAwesomeIcon icon={faEye} />
                      <Typography marginLeft={1}>
                        Visible, but disabled
                      </Typography>
                    </Fragment>
                  );
                case FeatureVisibilityState.Hidden:
                  return (
                    <Fragment>
                      <FontAwesomeIcon icon={faBan} />
                      <Typography marginLeft={1}>
                        Hidden and disabled
                      </Typography>
                    </Fragment>
                  );
                case FeatureVisibilityState.Enabled:
                  return (
                    <Fragment>
                      <FontAwesomeIcon icon={faCheck} />
                      <Typography marginLeft={1}>Enabled</Typography>
                    </Fragment>
                  );
              }
            }}
            value={state}
          >
            <Option value={FeatureVisibilityState.Disabled}>
              <FontAwesomeIcon icon={faEye} />
              Visible, but disabled
            </Option>
            <Option value={FeatureVisibilityState.Hidden}>
              <FontAwesomeIcon icon={faBan} />
              Hidden and disabled
            </Option>
            <Tooltip title={disableEnableOption && disableEnableOptionMessage}>
              <span>
                <Option
                  disabled={disableEnableOption}
                  value={FeatureVisibilityState.Enabled}
                >
                  <FontAwesomeIcon icon={faCheck} />
                  Enabled
                </Option>
              </span>
            </Tooltip>
          </Select>
        </Stack>
      </FormControl>
    );
  }
);

ConnectorStateSelect.displayName = "ConnectorStateSelect";

export default ConnectorStateSelect;
