import { Box, Grid, Stack, Typography } from "@mui/joy";
import { memo, useMemo } from "react";
import { DataNodeInvalidRowsInfo } from "features/dataNodes";
import DataNodeDataButton from "features/dataNodes/components/DataNodeConstructor/DataNodeDataButton";
import {
  type DatasetDetailsConfigContextValue,
  DatasetDetailsConfigProvider,
  type DatasetPopupConfigContextValue,
  DatasetPopupConfigProvider,
} from "features/datasets/contexts";
import {
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom/contexts";
import { type PublisherDataReportHookResult } from "features/mediaDataRoom/contexts/MediaDataRoomInsightsDataContext/usePublisherDataReport";

const PublishedDataNodeContent = memo(() => {
  const {
    publishedDatasetsHashes: {
      publisherDatasetsHashes: {
        matchingDatasetHash,
        segmentsDatasetHash,
        demographicsDatasetHash,
        embeddingsDatasetHash,
      },
    },
    publisherDataReport,
  } = useMediaDataRoomInsightsData();
  const {
    supportedFeatures: { enableDropInvalidRows },
  } = useMediaDataRoom();

  const datasetsWithLabels: {
    label: string;
    hash: string | undefined;
    reportDataKey: keyof PublisherDataReportHookResult | null;
  }[] = [
    {
      hash: matchingDatasetHash || undefined,
      label: "Users",
      reportDataKey: "matchingDataset" as const,
    },
    {
      hash: segmentsDatasetHash || undefined,
      label: "Segments",
      reportDataKey: "segmentsDataset" as const,
    },
    {
      hash: demographicsDatasetHash || undefined,
      label: "Demographics",
      reportDataKey: "demographicsDataset" as const,
    },
    // Embeddings dataset is not validated on the backend side, therefore there is no report for it
    {
      hash: embeddingsDatasetHash || undefined,
      label: "Embeddings",
      reportDataKey: null,
    },
  ].filter(({ hash }) => Boolean(hash));
  const datasetPopupConfig = useMemo<DatasetPopupConfigContextValue>(
    () => ({ disableDelete: true, disableExport: true }),
    []
  );
  const datasetDetailsConfig = useMemo<DatasetDetailsConfigContextValue>(
    () => ({
      disableDeprovision: true,
      disableProvisionedDataRoomNavigation: true,
    }),
    []
  );
  return (
    <DatasetDetailsConfigProvider value={datasetDetailsConfig}>
      <DatasetPopupConfigProvider value={datasetPopupConfig}>
        <Box sx={{ padding: (theme) => theme.spacing(1) }}>
          <Grid container={true} spacing={1}>
            {datasetsWithLabels.map(({ label, hash, reportDataKey }, index) => (
              <Grid key={index} xs={6}>
                <Typography fontWeight={500} level="body-sm">
                  {label}
                </Typography>
                <Stack alignItems="center" direction="row" spacing={0.5}>
                  <DataNodeDataButton
                    dataType="table"
                    datasetId={label.toLocaleLowerCase()}
                    datasetManifestHash={hash}
                  />
                  {enableDropInvalidRows &&
                  reportDataKey &&
                  publisherDataReport[reportDataKey] &&
                  publisherDataReport[reportDataKey].computeResults ? (
                    <DataNodeInvalidRowsInfo
                      datasetName={label}
                      iconOnly={true}
                      validationReport={
                        publisherDataReport[reportDataKey].computeResults
                      }
                    />
                  ) : null}
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>
      </DatasetPopupConfigProvider>
    </DatasetDetailsConfigProvider>
  );
});

PublishedDataNodeContent.displayName = "PublishedDataNodeContent";

export default PublishedDataNodeContent;
