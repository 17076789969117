import {
  useOrganizationFeaturesQuery,
  useOrganizationLicenseQuery,
  useUpdateOrganizationFlagsMutation,
  useUpdateOrganizationMicrosoftDspMemberMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  DataSourceType,
  DataTargetType,
  OrganizationState,
  type UpdateOrganizationFlagsInput,
} from "@decentriq/graphql/dist/types";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Checkbox,
  Chip,
  FormControl,
  FormLabel,
  Option,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/joy";
import { Fragment, memo, useCallback } from "react";
import { CommonSnackbarOrigin, useGeneralSnackbar, useUserRole } from "hooks";
import { FeatureVisibilityState, MicrosoftDspMember } from "models";
import { getEffectiveErrorMessage } from "utils";
import { ConnectorStateSelect } from "./components";

interface OrganizationFeaturesEditorProps {
  organizationId: string;
}

const OrganizationFeaturesEditor: React.FC<OrganizationFeaturesEditorProps> = ({
  organizationId,
}) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const { isSuperAdmin, isDecentriqAdmin } = useUserRole();

  const {
    data: {
      organization: {
        microsoftDspMember = MicrosoftDspMember.None,
        hasAdvertiserFeatures = false,
        hasAnalyticsFeatures = false,
        hasPublisherFeatures = false,
        hasDataPartnerFeatures = false,
        useLegacyDataLabs = false,
        canViewDataPartners = false,
        canViewMeasurements = false,
        showMigrationPrompt = false,
        allowExcludingSeedAudience = false,
        enableAudienceBuilder = false,
        // Data connectors - exports
        s3ExportState = FeatureVisibilityState.Disabled,
        metaExportState = FeatureVisibilityState.Disabled,
        googleDv360ExportState = FeatureVisibilityState.Disabled,
        tradedeskExportState = FeatureVisibilityState.Disabled,
        azureExportState = FeatureVisibilityState.Disabled,
        googleCloudStorageExportState = FeatureVisibilityState.Disabled,
        googleAdManagerExportState = FeatureVisibilityState.Disabled,
        permutiveExportState = FeatureVisibilityState.Disabled,
        sportradarExportState = FeatureVisibilityState.Disabled,
        adformExportState = FeatureVisibilityState.Disabled,
        microsoftDspExportState = FeatureVisibilityState.Disabled,
        splickyExportState = FeatureVisibilityState.Disabled,
        googleAdsExportState = FeatureVisibilityState.Disabled,
        snapchatExportState = FeatureVisibilityState.Disabled,
        tiktokExportState = FeatureVisibilityState.Disabled,
        // Data connectors - imports
        s3ImportState = FeatureVisibilityState.Disabled,
        snowflakeImportState = FeatureVisibilityState.Disabled,
        salesforceImportState = FeatureVisibilityState.Disabled,
        azureImportState = FeatureVisibilityState.Disabled,
        googleCloudStorageImportState = FeatureVisibilityState.Disabled,
        permutiveImportState = FeatureVisibilityState.Disabled,
        databricksImportState = FeatureVisibilityState.Disabled,
      } = {},
    } = {},
  } = useOrganizationFeaturesQuery({
    variables: { organizationId },
  });

  const { data: organizationLicenseData } = useOrganizationLicenseQuery({
    variables: { organizationId },
  });
  const organizationLicense = organizationLicenseData?.organization?.state;

  const [updateOrganizationFlagsMutation] =
    useUpdateOrganizationFlagsMutation();
  const [updateOrganizationMicrosoftDspMemberMutation] =
    useUpdateOrganizationMicrosoftDspMemberMutation();

  const updateOrganizationFlags = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      void updateOrganizationFlagsMutation({
        variables: {
          input: {
            [target.name as "allowExcludingSeedAudience"]: target.checked,
          },
          organizationId,
        },
      }).catch((error) => {
        enqueueSnackbar("Available feature settings could not be updated.", {
          context: getEffectiveErrorMessage(error),
          persist: true,
          variant: "error",
        });
      });
    },
    [updateOrganizationFlagsMutation, organizationId, enqueueSnackbar]
  );

  const updateConnectorState = useCallback(
    (
      connectorName: keyof UpdateOrganizationFlagsInput,
      state: FeatureVisibilityState
    ) => {
      void updateOrganizationFlagsMutation({
        variables: {
          input: {
            [connectorName as "tradedeskExportState"]: state,
          },
          organizationId,
        },
      }).catch((error) => {
        enqueueSnackbar("Available feature settings could not be updated.", {
          context: getEffectiveErrorMessage(error),
          persist: true,
          variant: "error",
        });
      });
    },
    [updateOrganizationFlagsMutation, organizationId, enqueueSnackbar]
  );

  const updateMicrosoftDspMember = useCallback(
    (microsoftDspMember: MicrosoftDspMember) => {
      void updateOrganizationMicrosoftDspMemberMutation({
        variables: {
          input: {
            id: organizationId,
            microsoftDspMember,
          },
        },
      }).catch((error) => {
        enqueueSnackbar("Microsoft DSP seat could not be updated.", {
          context: getEffectiveErrorMessage(error),
          persist: true,
          variant: "error",
        });
      });
    },
    [
      updateOrganizationMicrosoftDspMemberMutation,
      organizationId,
      enqueueSnackbar,
    ]
  );

  const isEditorDisabled =
    !organizationLicense ||
    [OrganizationState.Archived].includes(organizationLicense);

  const isPublisherCheckboxEnabled =
    organizationLicense === OrganizationState.Passive && isSuperAdmin;

  return (
    <FormControl>
      <Typography level="title-md">Features</Typography>
      <Stack
        direction="row"
        flexWrap="wrap"
        sx={{ "& > *": { flex: "0 1 calc(50% - 8px / 2)" } }}
      >
        {isSuperAdmin ? (
          <Fragment>
            <FormControl>
              <Checkbox
                checked={!!hasAdvertiserFeatures}
                disabled={isEditorDisabled}
                label="Advertiser features"
                name="hasAdvertiserFeatures"
                onChange={updateOrganizationFlags}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!hasAnalyticsFeatures}
                disabled={isEditorDisabled}
                label="Analytics features"
                name="hasAnalyticsFeatures"
                onChange={updateOrganizationFlags}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!hasPublisherFeatures}
                disabled={isEditorDisabled && !isPublisherCheckboxEnabled}
                label="Publisher features"
                name="hasPublisherFeatures"
                onChange={updateOrganizationFlags}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!hasDataPartnerFeatures}
                disabled={isEditorDisabled}
                label="Data partner features"
                name="hasDataPartnerFeatures"
                onChange={updateOrganizationFlags}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!canViewDataPartners}
                disabled={isEditorDisabled}
                label="Can view data partners"
                name="canViewDataPartners"
                onChange={updateOrganizationFlags}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!canViewMeasurements}
                disabled={isEditorDisabled}
                label="Can view measurements"
                name="canViewMeasurements"
                onChange={updateOrganizationFlags}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!allowExcludingSeedAudience}
                disabled={isEditorDisabled && !isPublisherCheckboxEnabled}
                label="Allow excluding seed audience"
                name="allowExcludingSeedAudience"
                onChange={updateOrganizationFlags}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!showMigrationPrompt}
                disabled={isEditorDisabled}
                label="Show migration prompt"
                name="showMigrationPrompt"
                onChange={updateOrganizationFlags}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!enableAudienceBuilder}
                disabled={isEditorDisabled}
                label="Enable audience builder DCR"
                name="enableAudienceBuilder"
                onChange={updateOrganizationFlags}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!useLegacyDataLabs}
                disabled={isEditorDisabled}
                label="Create 4.0 datalabs"
                name="useLegacyDataLabs"
                onChange={updateOrganizationFlags}
              />
            </FormControl>
          </Fragment>
        ) : (
          <Fragment>
            {hasAdvertiserFeatures && <Chip>Advertiser</Chip>}
            {hasAnalyticsFeatures && <Chip>Analytics</Chip>}
            {hasPublisherFeatures && <Chip>Publisher</Chip>}
            {hasDataPartnerFeatures && <Chip>Data partner</Chip>}
          </Fragment>
        )}
      </Stack>
      {isDecentriqAdmin && (
        <Fragment>
          <Stack spacing={1} sx={{ mb: 2 }}>
            <Typography level="title-md">Data export connectors</Typography>
            <ConnectorStateSelect
              connectionType={DataTargetType.S3}
              disabled={!isSuperAdmin}
              name="s3ExportState"
              onChange={updateConnectorState}
              state={s3ExportState}
            />
            <ConnectorStateSelect
              connectionType={DataTargetType.Azure}
              disabled={!isSuperAdmin}
              name="azureExportState"
              onChange={updateConnectorState}
              state={azureExportState}
            />
            <ConnectorStateSelect
              connectionType={DataTargetType.GoogleCloudStorage}
              disabled={!isSuperAdmin}
              name="googleCloudStorageExportState"
              onChange={updateConnectorState}
              state={googleCloudStorageExportState}
            />
            <ConnectorStateSelect
              connectionType={DataTargetType.Adform}
              disabled={!isSuperAdmin}
              name="adformExportState"
              onChange={updateConnectorState}
              state={adformExportState}
            />
            <ConnectorStateSelect
              connectionType={DataTargetType.GoogleAdManager}
              disabled={!isSuperAdmin}
              name="googleAdManagerExportState"
              onChange={updateConnectorState}
              state={googleAdManagerExportState}
            />
            <ConnectorStateSelect
              connectionType={DataTargetType.GoogleAds}
              disableEnableOption={true}
              disableEnableOptionMessage="The Google Ads connector is not implemented yet"
              disabled={!isSuperAdmin}
              name="googleAdsExportState"
              onChange={updateConnectorState}
              state={googleAdsExportState}
            />
            <ConnectorStateSelect
              connectionType={DataTargetType.GoogleDv_360}
              disabled={!isSuperAdmin}
              name="googleDv360ExportState"
              onChange={updateConnectorState}
              state={googleDv360ExportState}
            />
            <ConnectorStateSelect
              connectionType={DataTargetType.Meta}
              disabled={!isSuperAdmin}
              name="metaExportState"
              onChange={updateConnectorState}
              state={metaExportState}
            />
            <Stack
              direction="row"
              spacing={1}
              sx={{
                "& > *": {
                  width: "50%",
                },
              }}
            >
              <ConnectorStateSelect
                connectionType={DataTargetType.MicrosoftDsp}
                disableEnableOption={
                  microsoftDspMember === MicrosoftDspMember.None
                }
                disableEnableOptionMessage={
                  microsoftDspMember === MicrosoftDspMember.None
                    ? "The Microsoft DSP member needs to be selected first"
                    : undefined
                }
                disabled={!isSuperAdmin}
                name="microsoftDspExportState"
                onChange={updateConnectorState}
                state={microsoftDspExportState}
              />
              <FormControl>
                <Stack>
                  <FormLabel>
                    Microsoft DSP seat
                    <Tooltip title="This selects the account created for Decentriq by the Microsoft DSP seat owner. The Microsoft DSP connector can only be used if this value is set. This value determines where audiences are pushed to if a user of this organisation uses the Microsoft DSP connector. Only set it if you are certain that all audiences of this organisation should go into this seat.">
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </Tooltip>
                  </FormLabel>
                  <Select
                    onChange={(_, value) =>
                      updateMicrosoftDspMember(value as MicrosoftDspMember)
                    }
                    value={microsoftDspMember || MicrosoftDspMember.None}
                  >
                    {Object.values(MicrosoftDspMember).map((members) => (
                      <Option key={members} value={members}>
                        {members}
                      </Option>
                    ))}
                  </Select>
                </Stack>
              </FormControl>
            </Stack>
            <ConnectorStateSelect
              connectionType={DataTargetType.Permutive}
              disabled={!isSuperAdmin}
              name="permutiveExportState"
              onChange={updateConnectorState}
              state={permutiveExportState}
            />
            <ConnectorStateSelect
              connectionType={DataTargetType.Snapchat}
              disableEnableOption={true}
              disableEnableOptionMessage="The Snapchat connector is not implemented yet"
              disabled={!isSuperAdmin}
              name="snapchatExportState"
              onChange={updateConnectorState}
              state={snapchatExportState}
            />
            <ConnectorStateSelect
              connectionType={DataTargetType.Splicky}
              disabled={!isSuperAdmin}
              name="splickyExportState"
              onChange={updateConnectorState}
              state={splickyExportState}
            />
            <ConnectorStateSelect
              connectionType={DataTargetType.Sportradar}
              disabled={!isSuperAdmin}
              name="sportradarExportState"
              onChange={updateConnectorState}
              state={sportradarExportState}
            />
            <ConnectorStateSelect
              connectionType={DataTargetType.Tiktok}
              disableEnableOption={true}
              disableEnableOptionMessage="The TikTok connector is not implemented yet"
              disabled={!isSuperAdmin}
              name="tiktokExportState"
              onChange={updateConnectorState}
              state={tiktokExportState}
            />
            <ConnectorStateSelect
              connectionType={DataTargetType.TradeDesk}
              disabled={!isSuperAdmin}
              name="tradedeskExportState"
              onChange={updateConnectorState}
              state={tradedeskExportState}
            />
          </Stack>
          <Stack spacing={1} sx={{ mb: 2 }}>
            <Typography level="title-md">Data import connectors</Typography>
            <ConnectorStateSelect
              connectionType={DataSourceType.S3}
              disabled={!isSuperAdmin}
              name="s3ImportState"
              onChange={updateConnectorState}
              state={s3ImportState}
            />
            <ConnectorStateSelect
              connectionType={DataSourceType.Azure}
              disabled={!isSuperAdmin}
              name="azureImportState"
              onChange={updateConnectorState}
              state={azureImportState}
            />
            <ConnectorStateSelect
              connectionType={DataSourceType.GoogleCloudStorage}
              disabled={!isSuperAdmin}
              name="googleCloudStorageImportState"
              onChange={updateConnectorState}
              state={googleCloudStorageImportState}
            />
            <ConnectorStateSelect
              connectionType={DataSourceType.Databricks}
              disableEnableOption={true}
              disableEnableOptionMessage="The Databricks connector is not implemented yet"
              disabled={!isSuperAdmin}
              name="databricksImportState"
              onChange={updateConnectorState}
              state={databricksImportState}
            />
            <ConnectorStateSelect
              connectionType={DataSourceType.Permutive}
              disabled={!isSuperAdmin}
              name="permutiveImportState"
              onChange={updateConnectorState}
              state={permutiveImportState}
            />
            <ConnectorStateSelect
              connectionType={DataSourceType.Salesforce}
              disabled={!isSuperAdmin}
              name="salesforceImportState"
              onChange={updateConnectorState}
              state={salesforceImportState}
            />
            <ConnectorStateSelect
              connectionType={DataSourceType.Snowflake}
              disabled={!isSuperAdmin}
              name="snowflakeImportState"
              onChange={updateConnectorState}
              state={snowflakeImportState}
            />
          </Stack>
        </Fragment>
      )}
    </FormControl>
  );
};

OrganizationFeaturesEditor.displayName = "OrganizationFeaturesEditor";

export default memo(OrganizationFeaturesEditor);
