import { type ApolloClient, type NormalizedCacheObject } from "@apollo/client";
import {
  attestation,
  type Compiler,
  data_science,
  type data_science_commit,
  type data_science_data_room,
  proto,
  type Session,
  sql,
  type types,
} from "@decentriq/core";
import {
  type CompleteDraftDataRoomQuery,
  type CompletePublishedDataRoomQuery,
  type CompleteSubmittedDataRoomRequestQuery,
  type DraftNode,
  type PublishedDataset,
  TestModePublicationDocument,
} from "@decentriq/graphql/dist/types";
import { SmartVec } from "@decentriq/utils";
import { loadAsync } from "jszip";
import isEqual from "lodash/isEqual";
import * as forge from "node-forge";
import { parse, unparse } from "papaparse";
import {
  type DataNodeTypeNames,
  type DataRoomComputeNodeBase,
  type DataRoomDataNodeBase,
  type DataRoomDefinition,
} from "models";
import * as v0 from "utils/apicore/v0";
import * as v1 from "utils/apicore/v1";
import * as v2 from "utils/apicore/v2";
import * as v3 from "utils/apicore/v3";
import * as v4 from "utils/apicore/v4";
import * as v5 from "utils/apicore/v5";
import * as v6 from "utils/apicore/v6";
import * as v7 from "utils/apicore/v7";
import * as v8 from "utils/apicore/v8";
import * as v9 from "utils/apicore/v9";
import * as v10 from "utils/apicore/v10";
import { configuration } from "../configuration/configuration";
import {
  type BuildCommitFn,
  type BuildDcrFn,
  type ChangeCommitExecutionPermissionsFn,
  EnclaveSpecName,
  type FlattenDcrFn,
  type RebaseCommitFn,
  type TranslateCommitFn,
  type TranslateDcrFn,
} from "./shared";

export {
  containerComputationTypeToEnclaveType,
  EnclaveSpecName,
} from "./shared";

interface DcrBuilderFunctions {
  flattenDcr: FlattenDcrFn;
  translateCommit: TranslateCommitFn;
  translateDcr: TranslateDcrFn;
  buildCommit: BuildCommitFn;
  buildDcr: BuildDcrFn;
  rebaseCommit: RebaseCommitFn;
  changeCommitExecutionPermissions: ChangeCommitExecutionPermissionsFn;
}

const dcrBuilderFunctions: { [version: string]: DcrBuilderFunctions } = {
  v0: {
    buildCommit: v0.buildDataScienceCommit,
    buildDcr: v0.buildDataScienceDataRoom,
    changeCommitExecutionPermissions: v0.changeCommitExecutionPermissions,
    flattenDcr: v0.flattenInteractiveDataScienceDataRoom,
    rebaseCommit: v0.rebaseCommit,
    translateCommit: v0.translateDataScienceCommit,
    translateDcr: v0.translateDataScienceDataRoom,
  },
  v1: {
    buildCommit: v1.buildDataScienceCommit,
    buildDcr: v1.buildDataScienceDataRoom,
    changeCommitExecutionPermissions: v1.changeCommitExecutionPermissions,
    flattenDcr: v1.flattenInteractiveDataScienceDataRoom,
    rebaseCommit: v1.rebaseCommit,
    translateCommit: v1.translateDataScienceCommit,
    translateDcr: v1.translateDataScienceDataRoom,
  },
  v10: {
    buildCommit: v10.buildDataScienceCommit,
    buildDcr: v10.buildDataScienceDataRoom,
    changeCommitExecutionPermissions: v10.changeCommitExecutionPermissions,
    flattenDcr: v10.flattenInteractiveDataScienceDataRoom,
    rebaseCommit: v10.rebaseCommit,
    translateCommit: v10.translateDataScienceCommit,
    translateDcr: v10.translateDataScienceDataRoom,
  },
  v2: {
    buildCommit: v2.buildDataScienceCommit,
    buildDcr: v2.buildDataScienceDataRoom,
    changeCommitExecutionPermissions: v2.changeCommitExecutionPermissions,
    flattenDcr: v2.flattenInteractiveDataScienceDataRoom,
    rebaseCommit: v2.rebaseCommit,
    translateCommit: v2.translateDataScienceCommit,
    translateDcr: v2.translateDataScienceDataRoom,
  },
  v3: {
    buildCommit: v3.buildDataScienceCommit,
    buildDcr: v3.buildDataScienceDataRoom,
    changeCommitExecutionPermissions: v3.changeCommitExecutionPermissions,
    flattenDcr: v3.flattenInteractiveDataScienceDataRoom,
    rebaseCommit: v3.rebaseCommit,
    translateCommit: v3.translateDataScienceCommit,
    translateDcr: v3.translateDataScienceDataRoom,
  },
  v4: {
    buildCommit: v4.buildDataScienceCommit,
    buildDcr: v4.buildDataScienceDataRoom,
    changeCommitExecutionPermissions: v4.changeCommitExecutionPermissions,
    flattenDcr: v4.flattenInteractiveDataScienceDataRoom,
    rebaseCommit: v4.rebaseCommit,
    translateCommit: v4.translateDataScienceCommit,
    translateDcr: v4.translateDataScienceDataRoom,
  },
  v5: {
    buildCommit: v5.buildDataScienceCommit,
    buildDcr: v5.buildDataScienceDataRoom,
    changeCommitExecutionPermissions: v5.changeCommitExecutionPermissions,
    flattenDcr: v5.flattenInteractiveDataScienceDataRoom,
    rebaseCommit: v5.rebaseCommit,
    translateCommit: v5.translateDataScienceCommit,
    translateDcr: v5.translateDataScienceDataRoom,
  },
  v6: {
    buildCommit: v6.buildDataScienceCommit,
    buildDcr: v6.buildDataScienceDataRoom,
    changeCommitExecutionPermissions: v6.changeCommitExecutionPermissions,
    flattenDcr: v6.flattenInteractiveDataScienceDataRoom,
    rebaseCommit: v6.rebaseCommit,
    translateCommit: v6.translateDataScienceCommit,
    translateDcr: v6.translateDataScienceDataRoom,
  },
  v7: {
    buildCommit: v7.buildDataScienceCommit,
    buildDcr: v7.buildDataScienceDataRoom,
    changeCommitExecutionPermissions: v7.changeCommitExecutionPermissions,
    flattenDcr: v7.flattenInteractiveDataScienceDataRoom,
    rebaseCommit: v7.rebaseCommit,
    translateCommit: v7.translateDataScienceCommit,
    translateDcr: v7.translateDataScienceDataRoom,
  },
  v8: {
    buildCommit: v8.buildDataScienceCommit,
    buildDcr: v8.buildDataScienceDataRoom,
    changeCommitExecutionPermissions: v8.changeCommitExecutionPermissions,
    flattenDcr: v8.flattenInteractiveDataScienceDataRoom,
    rebaseCommit: v8.rebaseCommit,
    translateCommit: v8.translateDataScienceCommit,
    translateDcr: v8.translateDataScienceDataRoom,
  },
  v9: {
    buildCommit: v9.buildDataScienceCommit,
    buildDcr: v9.buildDataScienceDataRoom,
    changeCommitExecutionPermissions: v9.changeCommitExecutionPermissions,
    flattenDcr: v9.flattenInteractiveDataScienceDataRoom,
    rebaseCommit: v9.rebaseCommit,
    translateCommit: v9.translateDataScienceCommit,
    translateDcr: v9.translateDataScienceDataRoom,
  },
};

const availableDcrVersions = Object.getOwnPropertyNames(dcrBuilderFunctions);
const availableDcrVersionsSorted = availableDcrVersions.sort((a, b) => {
  // Extract the numeric part after "v" and compare them as numbers
  const numA = parseInt(a.slice(1), 10);
  const numB = parseInt(b.slice(1), 10);
  return numA - numB;
});
const latestDcrVersion =
  availableDcrVersionsSorted[availableDcrVersionsSorted.length - 1];

function getDcrBuilderFunctions(version: string): DcrBuilderFunctions {
  if (version in dcrBuilderFunctions) {
    return dcrBuilderFunctions[version];
  } else {
    throw new Error(
      `No available set of builder functions for DCR with version '${version}'.` +
        ` Latest available version: '${latestDcrVersion}'.`
    );
  }
}

export interface DataRoomExport {
  createdAt: string;
  dataScienceDataRoom: data_science_data_room.DataScienceDataRoom;
}

export interface ComputationJobStatus {
  completed: boolean;
}

export type TableScheme = sql.proto.TableSchema;

export type CSVResult = {
  filename: string;
  result: string[][];
};

export interface IComputationResult {
  schema?: sql.proto.TableSchema;
  logs?: string;
  images?: string[];
}

export interface ComputationResultMeta extends IComputationResult {
  data: CSVResult[];
}

export interface ComputationValidationResultMeta extends IComputationResult {
  data: string[][];
}

export type DecodedComputationResultMeta = [string, sql.proto.TableSchema];

/**
 * The two files making up a tabular data output after
 * having been extracted from the zip file received from
 * the enclave.
 * The first element is the UTF-8 encoded CSV file (without
 * the header).
 */
export type ExtractedDatasetWithSchema = [Uint8Array, sql.proto.TableSchema];

export const toHex = (bytes: Uint8Array): string =>
  forge.util.binary.hex.encode(bytes);

export const fromHex = (hex: string): Uint8Array =>
  forge.util.binary.hex.decode(hex);

export interface ComputationValidationResponse {
  computeNodeId: string;
  raw: Uint8Array;
  meta?: ComputationResultMeta;
}

export enum DataRoomStatus {
  ACTIVE = 0,
  STOPPED = 1,
}

export interface ComputationResult {
  status: "running" | "finished" | "cancelled";
  bytes?: Uint8Array;
  jobId?: Uint8Array;
}

export interface RunAllComputationsResult {
  status: "running" | "finished";
  jobId?: Uint8Array;
  outcome?: any;
}

export async function parseComputationResult(
  results: Uint8Array
): Promise<ComputationValidationResultMeta> {
  const zip = await loadAsync(results);
  const resultsDataset = await zip.file("dataset.csv")!.async("uint8array");
  const containerLog = await zip.file("container.log")?.async("uint8array");
  const decodedContainerLog = containerLog
    ? new TextDecoder().decode(containerLog)
    : undefined;
  const resultsCsv = parse(new TextDecoder().decode(resultsDataset), {
    skipEmptyLines: true,
  }).data;
  const resultsTypes = sql.proto.TableSchema.decodeDelimited(
    await zip.file("types")!.async("uint8array")
  );
  return {
    data: resultsCsv as string[][],
    logs: decodedContainerLog,
    schema: resultsTypes,
  };
}

/**
 * Extract the dataset as a UTF-8 bytearray and its type definition
 * from the given enclave result. Note that this function avoids
 * edcoding the UTF-8 bytearray as its result might be larger than V8's
 * max string size.
 */
export async function extractDatasetFromTabularResult(
  results: Uint8Array
): Promise<ExtractedDatasetWithSchema> {
  const zip = await loadAsync(results);
  const resultsDataset =
    (await zip.file("dataset.csv")?.async("uint8array")) || new Uint8Array();
  const resultsTypes = sql.proto.TableSchema.decodeDelimited(
    await zip.file("types")!.async("uint8array")
  );
  return [resultsDataset, resultsTypes];
}

export async function decodeComputationResult(
  results: Uint8Array
): Promise<DecodedComputationResultMeta> {
  const [encodedCsv, resultsTypes] =
    await extractDatasetFromTabularResult(results);
  const resultsCsv = new TextDecoder().decode(encodedCsv);
  return [resultsCsv, resultsTypes];
}

export async function convertTabularResultToCsvFile(
  results: Uint8Array,
  fileName: string
): Promise<File> {
  const [content, tableSchema] = await extractDatasetFromTabularResult(results);
  const contentHeaders =
    tableSchema.namedColumns.map((n) => n.name || "") || [];
  const header =
    contentHeaders && contentHeaders.length
      ? unparse([contentHeaders]) + "\n"
      : "";
  const encodedHeader = new TextEncoder().encode(header);

  // Make sure that we don't try to create an actual String here as we might hit
  // V8's string size limit (around 500M).
  const fullCsvBuffer = SmartVec.withSize(
    encodedHeader.length + content.length
  );
  fullCsvBuffer.extend(encodedHeader);
  fullCsvBuffer.extend(content);

  return new File([fullCsvBuffer.view()], fileName, {
    type: "application/octet-stream;charset=utf-8",
  });
}

export async function getScriptingNodeFileResult(
  results: Uint8Array
): Promise<ComputationResultMeta> {
  const zip = await loadAsync(results);
  const images =
    Object.values(zip.files).filter(({ name = "" }) =>
      name.match(/.png|.gif|.jpg|.jpeg|.webp|.bmp|.tif|.tiff/)
    ) || [];
  const csvs = Object.keys(zip.files).filter((filename) =>
    filename.endsWith(".csv")
  );
  const containerLog = await zip.file("container.log")?.async("uint8array");
  const decodedContainerLog = containerLog
    ? new TextDecoder().decode(containerLog)
    : undefined;
  let imagesResult: string[] = [];
  let csvResult: CSVResult[] = [];
  // Parse images result if available and assign value
  if (images.length) {
    const result = await Promise.all(
      images.map(async ({ name = "" }) => {
        const base64Image = await zip.file(name)?.async("base64");
        return base64Image!;
      })
    );
    imagesResult = result.filter((image) => Boolean(image));
  }
  // Parse csvs result if available and assign value
  if (csvs.length) {
    csvResult = await Promise.all(
      csvs.map(async (filename: string) => {
        const resultsDataset = await zip.file(filename)!.async("uint8array");
        const result = parse(new TextDecoder().decode(resultsDataset), {
          skipEmptyLines: true,
        }).data as string[][];
        return {
          filename,
          result,
        };
      })
    );
  }
  return { data: csvResult, images: imagesResult, logs: decodedContainerLog };
}

export async function getMatchNodeResult(results: Uint8Array): Promise<any> {
  const zip = await loadAsync(results);
  const containerLog = await zip.file("container.log")?.async("uint8array");
  const decodedContainerLog = containerLog
    ? new TextDecoder().decode(containerLog)
    : undefined;
  const csvs = Object.keys(zip.files).filter((filename) =>
    filename.endsWith("statistics.csv")
  );
  const data: any = {};
  for (const filename of csvs) {
    const csvContent = await zip.file(filename)?.async("uint8array");
    const decodedCsvContent = csvContent
      ? new TextDecoder().decode(csvContent)
      : "";
    const parsedCsvContent = parse(decodedCsvContent, {
      skipEmptyLines: true,
    }).data;
    data[filename] = parsedCsvContent;
  }
  const resultsTypes = sql.proto.TableSchema.decodeDelimited(
    await zip.file("types")!.async("uint8array")
  );
  return { data, logs: decodedContainerLog, schema: resultsTypes };
}

export async function getSyntheticDataReportFromResults(
  results: Uint8Array
): Promise<{
  reportUrl: string | undefined;
  error: { message: string } | undefined;
}> {
  try {
    const zip = await loadAsync(results);
    const reportBlob = await zip.file("report.html")?.async("uint8array");
    if (reportBlob) {
      const blob = new Blob([reportBlob], { type: "text/html" });
      const reportUrl = URL.createObjectURL(blob);
      return {
        error: undefined,
        reportUrl,
      };
    }
    const errorBlob = await zip.file("error.json")?.async("uint8array");
    const error = errorBlob
      ? JSON.parse(new TextDecoder().decode(errorBlob))
      : undefined;
    return {
      error,
      reportUrl: undefined,
    };
  } catch (error) {
    return {
      error:
        error instanceof Error ? error : { message: "Failed to get report" },
      reportUrl: undefined,
    };
  }
}

export const idAsString = (jobId: Uint8Array): string => jobId.toString();

export const idAsArray = (jobId: string): Uint8Array =>
  Uint8Array.from(jobId.split(",") as any);

export const idAsHash = (
  jobId: string | Uint8Array | undefined
): string | undefined => {
  if (!jobId) {
    return undefined;
  }
  const array = typeof jobId === "string" ? idAsArray(jobId) : jobId;
  let value = "";
  // map & join doesn't work for Uint8Array
  array.forEach((n) => {
    value += n.toString(16).padStart(2, "0");
  });
  return value;
};

export const hashedIdAsArray = (hash: string) =>
  Uint8Array.from(
    hash
      .split(/(..)/)
      .filter(Boolean)
      .map((group) => parseInt(group, 16))
  );

export const getDatasetLeafNodeId = (nodeId: string): string =>
  `@table/${nodeId}/dataset`;

export const getDatasetValidationNodeId = (nodeId: string): string =>
  `@table/${nodeId}/validation`;

export const getDataNodeNameFromLeafNode = (leafName: string): string =>
  new RegExp(/@table\/(?<node>.+)\/dataset/gm).exec(leafName)!.groups!.node;

export const getDataNodeNameFromValidationNode = (leafName: string): string =>
  new RegExp(/@table\/(?<node>.+)\/validation/gm).exec(leafName)!.groups!.node;

export const findGcgDriverSpec = (
  enclaveSpecs: types.EnclaveSpecification[],
  mrenclave?: string
) => {
  const enclaveSpecsArray = Array.from(enclaveSpecs.values());
  if (!mrenclave) {
    return getLatestEnclaveSpec(enclaveSpecsArray, EnclaveSpecName.GCG);
  }
  const specs = fromMrenclaveToEnclaveSpecs(mrenclave, enclaveSpecsArray);
  if (specs.length === 0) {
    throw new Error(`No driver enclave with id ${mrenclave} available`);
  }
  const gcgSpec = specs.find((spec) => spec?.name === EnclaveSpecName.GCG);
  if (!gcgSpec) {
    throw new Error(`No driver enclave with id ${mrenclave} available`);
  }
  return gcgSpec;
};

export const fromMrenclaveToEnclaveSpecs = (
  mrenclave: string,
  enclaveSpecs: types.EnclaveSpecification[]
): types.EnclaveSpecification[] => {
  return mrenclave
    .toString()
    .split("::")
    .map((hash) => getEnclaveSpecByHash(enclaveSpecs, hash))
    .filter((spec): spec is types.EnclaveSpecification => !!spec);
};

export const getMrenclave = (
  usedAttestationSpecs: proto.attestation.IAttestationSpecification[]
): string => {
  const hashList: string[] = [];
  usedAttestationSpecs.forEach((spec) => {
    const hash = hashAttestationSpec(spec);
    if (!hashList.includes(hash)) {
      hashList.push(hash);
    }
  });
  return hashList.join("::");
};

export const getScriptingInputDependencyId = (
  nodeId: string,
  computeNodes: DataRoomComputeNodeBase[],
  dataNodes: DataRoomDataNodeBase[]
) => {
  const unstructuredNode = dataNodes.find(
    (n) => n.computeNodeId === nodeId && n.config.config.validation.unstructured
  );
  if (unstructuredNode) {
    return getDatasetLeafNodeId(unstructuredNode.computeNodeId);
  }
  return nodeId;
};

export const getScriptingInputDependencyName = (
  nodeId: string,
  computeNodes: DataRoomComputeNodeBase[],
  dataNodes: DataRoomDataNodeBase[]
) => {
  const computeNode = computeNodes.find((n) => n.computeNodeId === nodeId);
  if (computeNode) {
    return computeNode.nodeName;
  }
  const dataNode = dataNodes.find((n) => n.computeNodeId === nodeId);
  if (dataNode) {
    return dataNode.nodeName;
  }
  throw Error("Invalid input dependency");
};

export const getLatestEnclaveSpec = (
  enclaveSpecs: types.EnclaveSpecification[],
  name: EnclaveSpecName
) => {
  return enclaveSpecs
    .filter((spec) => spec.name === name)
    .sort((a, b) => Number(b.version) - Number(a.version))[0];
};

export const getEnclaveSpecByHash = (
  enclaveSpecs: types.EnclaveSpecification[],
  hash: string
) => {
  return enclaveSpecs.find((spec) => hashAttestationSpec(spec.proto) === hash);
};

export const hashAttestationSpec = (
  spec: proto.attestation.IAttestationSpecification
) => {
  const md = forge.md.sha256.create();
  md.update(
    forge.util.binary.raw.encode(
      proto.attestation.AttestationSpecification.encodeDelimited(spec).finish()
    )
  );
  return md.digest().toHex();
};

export const isInsecureSpec = (spec: types.EnclaveSpecification) => {
  const { intelEpid, intelDcap, amdSnp, intelDcapMrsigner } = spec.proto;

  if (intelEpid) {
    return Boolean(
      intelEpid.acceptDebug ||
        intelEpid.acceptGroupOutOfDate ||
        intelEpid.acceptConfigurationNeeded
    );
  }

  if (intelDcap) {
    return Boolean(
      intelDcap.acceptDebug ||
        intelDcap.acceptOutOfDate ||
        intelDcap.acceptConfigurationNeeded ||
        intelDcap.acceptRevoked
    );
  }

  if (intelDcapMrsigner) {
    return Boolean(
      intelDcapMrsigner.acceptDebug ||
        intelDcapMrsigner.acceptOutOfDate ||
        intelDcapMrsigner.acceptConfigurationNeeded ||
        intelDcapMrsigner.acceptRevoked ||
        !intelDcapMrsigner.mrsigner ||
        forge.util.binary.hex.encode(intelDcapMrsigner.mrsigner) !==
          "469ba38fe548952095beed8daf89f9629a9bd1644a01e218e770569b1e669ee9"
    );
  }

  if (amdSnp) {
    const validAmdArkDer = isEqual(amdSnp.amdArkDer, attestation.amdSnpArkDer);
    let validRoughtimePubKey = false;
    if (
      (spec.name === "decentriq.python-ml-worker-32-64" &&
        parseInt(spec.version) >= 23) ||
      (spec.name === "decentriq.python-synth-data-worker-32-64" &&
        parseInt(spec.version) >= 18) ||
      spec.name === "decentriq.r-ml-worker-32-32"
    ) {
      validRoughtimePubKey = isEqual(
        amdSnp.roughtimePubKey,
        attestation.newRoughtimePublicKey
      );
    } else {
      validRoughtimePubKey = isEqual(
        amdSnp.roughtimePubKey,
        attestation.roughtimePublicKey
      );
    }
    // Only newer container enclaves have a decentriqDer so we need to check if it exists
    const validDecentriqDer =
      amdSnp.decentriqDer && amdSnp.decentriqDer.length
        ? isEqual(
            amdSnp.decentriqDer,
            attestation.decentriqChipIdEndorsementRoot
          )
        : true;
    // Only older container enclaves have authorizedChipIds, but this check also works for newer ones
    // since the list of authorizedChipIds is empty
    const validAuthorizedChipId =
      amdSnp.authorizedChipIds && amdSnp.authorizedChipIds.length
        ? amdSnp.authorizedChipIds.every((chip_id) =>
            attestation.oldAmdSnpAuthorizedChipIds.some((old_chip_id) =>
              isEqual(old_chip_id, chip_id)
            )
          )
        : true;
    return !(
      validAmdArkDer &&
      validRoughtimePubKey &&
      validDecentriqDer &&
      validAuthorizedChipId
    );
  }

  return true;
};

export const validateDataRoomForPublishing = (dataRoom: DataRoomDefinition) => {
  const isInteractivityEnabled =
    Boolean(dataRoom?.governanceProtocol?.dataOwnersPolicy) ||
    dataRoom?.enableDevelopmentTab;
  if (!dataRoom.dataRoomDataNodes?.nodes?.length) {
    throw Error("At least one table should be added");
  }
  if (
    !dataRoom.dataRoomComputeNodes?.nodes?.length &&
    !isInteractivityEnabled
  ) {
    throw Error("At least one query should be added");
  }
  const allPermissions = dataRoom.userPermissions?.nodes?.flatMap(
    (n) => n.permissions.nodes
  );
  if (
    !allPermissions?.some(
      (p) => p?.config.nodeType === "table" || p?.config.nodeType === "file"
    )
  ) {
    throw Error("At least one Data Owner should be added");
  }
  if (
    dataRoom.dataRoomComputeNodes?.nodes?.length &&
    !allPermissions?.some((p) => p?.config.nodeType === "query")
  ) {
    throw Error("At least one Analyst should be added");
  }
  if (
    !dataRoom.userPermissions.nodes?.some(
      (p) => p.email === dataRoom.ownerEmail
    )
  ) {
    throw Error("DCR Creator must also be a participant");
  }
};

export const validateDataRoomSyntheticDataNodes = (
  dataRoom: DataRoomDefinition
) => {
  if (!dataRoom.dataRoomComputeNodes.nodes?.length) {
    return;
  }
  for (let i = 0; i < dataRoom.dataRoomComputeNodes.nodes.length; i++) {
    const node = dataRoom.dataRoomComputeNodes.nodes[i];
    if (node.config.config.computationType === "synthetic_data") {
      const {
        computation: {
          syntheticDataMaskingConfig,
          syntheticDataSource,
          syntheticDataSourceUpdatedAt,
        },
      } = node.config.config;
      if (!syntheticDataSource) {
        throw Error(`'${node.nodeName}' failed: data source is not specified`);
      }
      if (!syntheticDataMaskingConfig?.length) {
        throw Error(
          `'${node.nodeName}' failed: data source must have at least one column`
        );
      }
      if (syntheticDataMaskingConfig.some((c) => c.index === undefined)) {
        throw Error(
          `'${node.nodeName}' failed: settings are outdated. Please update the data source schema.`
        );
      }
      const source = dataRoom.dataRoomDataNodes?.nodes?.find(
        (tN) => tN.computeNodeId === syntheticDataSource
      );
      if (source) {
        if (
          syntheticDataMaskingConfig.length !==
            source.config.config.validation?.tableSchema?.namedColumns.length ||
          !source.config.config.validation?.tableSchema.namedColumns.every(
            (tC) =>
              syntheticDataMaskingConfig.some((c) =>
                isEqual(tC, c.columnDefinition)
              )
          )
        ) {
          throw Error(
            `There is a schema conflict in the synthetic data computation '${node.nodeName}'`
          );
        }
      } else {
        const sqlSource = dataRoom.dataRoomComputeNodes?.nodes?.find(
          (qN) => qN.computeNodeId === syntheticDataSource
        );
        if (!sqlSource) {
          throw Error(`'${node.nodeName}' failed: unexisting data source`);
        }
        if (sqlSource.updatedAt !== syntheticDataSourceUpdatedAt) {
          throw Error(
            `There is a schema conflict in the synthetic data computation '${node.nodeName}'`
          );
        }
      }
    }
  }
};

export const validateDataRoomScriptingNodes = (
  dataRoom: DataRoomDefinition
) => {
  if (!dataRoom.dataRoomComputeNodes.nodes?.length) {
    return;
  }
};

export const validateDataRoomS3DataNodes = (dataRoom: DataRoomDefinition) => {
  if (!dataRoom.dataRoomComputeNodes.nodes?.length) {
    return;
  }
  for (let i = 0; i < dataRoom.dataRoomComputeNodes.nodes.length; i++) {
    const node = dataRoom.dataRoomComputeNodes.nodes[i];
    if (node.config.config.computationType === "s3") {
      const hasEmptyFields = Object.values(node.config.config.computation).some(
        (value) => !value
      );
      if (hasEmptyFields) {
        throw Error(
          `"${node.nodeName}" failed: please make sure all fields are filled in`
        );
      }
    }
  }
};

export class ComputeNodeAssertionError extends Error {
  constructor(
    public type: "table" | "query",
    public computeNodeName: string,
    public index: number,
    message: string
  ) {
    super(message);
  }

  get synopsis() {
    return `"${this.computeNodeName}" failed: ${this.message}`;
  }

  toString(): string {
    return `ComputeNodeAssertionError: ${this.message} for ${this.type} ${this.computeNodeName}`;
  }
}

export const getLatestEnclaveSpecsPerType = (
  specs: types.EnclaveSpecification[]
): Map<string, types.EnclaveSpecification> => {
  return attestation.getLatestEnclaveSpecsPerType(
    specs,
    configuration.insecureEnclavesEnabled
  );
};

export function findScriptingInputDependenciesIds({
  nodeInputs,
  dataNodeIds,
  computeNodeIds,
}: {
  nodeInputs: string[];
  dataNodeIds?: string[];
  computeNodeIds?: string[];
}) {
  return [
    ...(dataNodeIds || []).filter((n) => nodeInputs.includes(n)),
    ...(computeNodeIds || []).filter((n) => nodeInputs.includes(n)),
  ];
}

export interface DcrProperties {
  version: string;
  isInteractive: boolean;
  title: string;
}

export interface CommitProperties {
  version: string;
}

// TODO: Move this to WASM and get the properties in a less insane way
export function getDcrProperties(
  dataScienceDataRoom: data_science_data_room.DataScienceDataRoom
): DcrProperties {
  let version: string | undefined;
  let title: string | undefined;
  for (const v of availableDcrVersions) {
    if (v in dataScienceDataRoom) {
      version = v;
      break;
    }
  }
  let isInteractive = false;
  if (version !== undefined) {
    const dcr = (dataScienceDataRoom as any)[version];
    if (dcr.interactive) {
      isInteractive = true;
    }
    if (isInteractive) {
      title = dcr.interactive.initialConfiguration.title;
    } else {
      title = dcr.static.title;
    }
  } else {
    throw new Error("Unable to determine version for given data science DCR");
  }
  if (title !== undefined) {
    return { isInteractive, title, version };
  } else {
    throw new Error("Unable to determine DCR title");
  }
}

export function getCommitProperties(
  commit: data_science_commit.DataScienceCommit
): CommitProperties {
  let version: string | undefined;
  for (const v of availableDcrVersions) {
    if (v in commit) {
      version = v;
      break;
    }
  }
  if (version !== undefined) {
    return {
      version,
    };
  } else {
    throw new Error("Unable to determine version for given commit");
  }
}

function flattenInteractiveDataScienceDataRoom(
  dcr: data_science_data_room.DataScienceDataRoom
): data_science_data_room.DataScienceDataRoom {
  const { version } = getDcrProperties(dcr);
  if (version === undefined) {
    throw new Error("Unable to determine version for DCR");
  }
  if (version in dcrBuilderFunctions) {
    const { flattenDcr } = getDcrBuilderFunctions(version);
    return flattenDcr(dcr);
  } else {
    throw new Error(
      `No available set of builder functions for DCR with version '${version}'.` +
        ` Latest available version: '${latestDcrVersion}'.`
    );
  }
}

export function upgradeDataScienceDataRoom(
  compiler: Compiler,
  dataScienceDataRoom: data_science_data_room.DataScienceDataRoom
): data_science_data_room.DataScienceDataRoom {
  const flattenedDcr =
    flattenInteractiveDataScienceDataRoom(dataScienceDataRoom);
  return compiler.convertDataScienceDataRoomAnyToLatest(flattenedDcr);
}

export function exportDataScienceDataRoom(
  compiler: Compiler,
  dataScienceDataRoom: data_science_data_room.DataScienceDataRoom
): DataRoomExport {
  const latestDataScienceDataRoom = upgradeDataScienceDataRoom(
    compiler,
    dataScienceDataRoom
  );
  return {
    createdAt: new Date().toISOString(),
    dataScienceDataRoom: latestDataScienceDataRoom,
  };
}

/** Translate the high-level data science DCR into the UI representation */
export function translateDataScienceDataRoom(
  compiler: Compiler,
  dataScienceDataRoom: data_science_data_room.DataScienceDataRoom,
  driverAttestationHash: string,
  dcrId: string,
  isStopped: boolean,
  publishedDatasetByNode: Map<string, proto.gcg.IPublishedDataset>
): CompletePublishedDataRoomQuery["publishedDataRoom"] {
  const converted =
    compiler.convertDataScienceDataRoomAnyToLatest(dataScienceDataRoom);
  if (latestDcrVersion in converted) {
    const fns = getDcrBuilderFunctions(latestDcrVersion);
    return fns.translateDcr(
      converted,
      driverAttestationHash,
      dcrId,
      isStopped,
      publishedDatasetByNode
    );
  } else {
    throw new Error(
      "Cannot translate DataScienceDataRoom: version should be the latest"
    );
  }
}

export function translateDataScienceCommit(
  compiler: Compiler,
  submittedDataRoomRequestId: string,
  dataScienceDataRoom: data_science_data_room.DataScienceDataRoom,
  commit: data_science_commit.DataScienceCommit,
  driverAttestationHash: string,
  dcrId: string,
  commitId: string
): CompleteSubmittedDataRoomRequestQuery["submittedDataRoomRequest"] {
  const latestDataRoom =
    compiler.convertDataScienceDataRoomAnyToLatest(dataScienceDataRoom);
  const latestCommit = compiler.convertDataScienceCommitAnyToLatest(commit);
  if (latestDcrVersion in latestDataRoom && latestDcrVersion in latestCommit) {
    const fns = getDcrBuilderFunctions(latestDcrVersion);
    return fns.translateCommit(
      submittedDataRoomRequestId,
      latestDataRoom,
      latestCommit,
      driverAttestationHash,
      dcrId,
      commitId
    );
  } else {
    throw new Error("Incompatible or unsupported data room and commit version");
  }
}

export const buildDataScienceCommit: BuildCommitFn = (
  draftNode: DraftNode,
  analysts: Array<string>,
  dataRoomId: string,
  historyPin: string,
  availableSpecs: Map<string, types.EnclaveSpecification>,
  usedSpecs: string[],
  nodeNamesMap: Map<string, string>,
  isDevMode: boolean
) => {
  const fns = getDcrBuilderFunctions(latestDcrVersion);
  return fns.buildCommit(
    draftNode,
    analysts,
    dataRoomId,
    historyPin,
    availableSpecs,
    usedSpecs,
    nodeNamesMap,
    isDevMode
  );
};

export const buildDataScienceDataRoom: BuildDcrFn = (
  draftDataRoom: CompleteDraftDataRoomQuery["draftDataRoom"],
  rootCertificatePem: Uint8Array,
  availableSpecs: Map<string, types.EnclaveSpecification>,
  isDraftMode: boolean,
  dcrSecretIdBase64?: string
) => {
  const driverSpecs: types.EnclaveSpecification[] = Array.from(
    availableSpecs.values()
  ).filter((spec) => spec.name === "decentriq.driver");
  if (driverSpecs.every((spec) => spec.version === "10")) {
    throw new Error(
      "Cannot create a new DataScienceDataRoom for a a driver not supporting v1 DataScienceDataRoom"
    );
  }
  const fns = getDcrBuilderFunctions(latestDcrVersion);
  return fns.buildDcr(
    draftDataRoom,
    rootCertificatePem,
    availableSpecs,
    isDraftMode,
    dcrSecretIdBase64
  );
};

export const retrieveTestPublishedDatasets = async ({
  client,
  nodes,
  dataRoomId,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  nodes: { __typename: DataNodeTypeNames; id: string }[];
  dataRoomId: string;
}): Promise<Map<string, PublishedDataset | null>> => {
  const datasetsByNodeId = new Map<string, PublishedDataset | null>();
  for await (const { id, __typename } of nodes) {
    try {
      const { data } = await client.query({
        errorPolicy: "ignore",
        fetchPolicy: "network-only",
        query: TestModePublicationDocument,
        variables: {
          id: {
            published: {
              computeNodeId: id,
              publishedDataRoomId: dataRoomId,
            },
          },
        },
      });
      let publishedDataset: PublishedDataset | null = null;
      if (data?.testModePublication?.dataset) {
        const {
          manifestHash: datasetHash,
          createdAt: timestamp,
          owner: { email: user },
        } = data.testModePublication.dataset;
        publishedDataset = {
          datasetHash,
          leafId: `${id}${
            __typename === "DraftRawLeafNode" ||
            __typename === "PublishedRawLeafNode"
              ? ""
              : "_leaf"
          }`,
          timestamp,
          user,
        };
      }
      datasetsByNodeId.set(id, publishedDataset);
    } catch (error) {
      datasetsByNodeId.set(id, null);
    }
  }
  return datasetsByNodeId;
};
/**
 * Assuming there is a high-level node "node" made up of several
 * low-level nodes that depend on one another ["node1" -> "node2" -> "node3"],
 * this function will return the last node, i.e. the node on which a downstream
 * high-level computation has to depend on ("node3").
 */
export async function getLowLevelDependencyNodeId(
  session: Session,
  dataRoomId: string,
  computeNodeId: string
): Promise<string> {
  const dcrResponse = await session.retrieveDataRoom(dataRoomId);
  const dsDcr = await session.constructVerifiedDataScienceDataRoom(dcrResponse);
  const wrapper = data_science.createDataScienceDataRoomWrapper(
    dataRoomId,
    dsDcr!,
    session
  );
  const upstreamLowLevelNodeId =
    wrapper.getEnclaveDependencyNodeIdForNode(computeNodeId);
  return upstreamLowLevelNodeId;
}

export const rebaseCommit: RebaseCommitFn = (
  commit: data_science_commit.DataScienceCommit,
  newHistoryPin: string,
  usedSpecIds: string[]
) => {
  const { version } = getCommitProperties(commit);
  if (version in dcrBuilderFunctions) {
    const { rebaseCommit } = getDcrBuilderFunctions(version);
    rebaseCommit(commit, newHistoryPin, usedSpecIds);
  } else {
    throw new Error(
      `No available set of builder functions for commit with version '${version}'.`
    );
  }
};

export const changeCommitExecutionPermissions: ChangeCommitExecutionPermissionsFn =
  (commit: data_science_commit.DataScienceCommit, user: string) => {
    const { version } = getCommitProperties(commit);
    if (version in dcrBuilderFunctions) {
      const { changeCommitExecutionPermissions } =
        getDcrBuilderFunctions(version);
      changeCommitExecutionPermissions(commit, user);
    } else {
      throw new Error(
        `No available set of builder functions for commit with version '${version}'.`
      );
    }
  };
