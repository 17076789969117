import { createContext, type SetStateAction, useContext } from "react";
import {
  type LookalikeAudienceStatistics,
  type MediaDataRoomJobHookResult,
} from "features/mediaDataRoom/hooks";
import {
  type AdvertiserAudience,
  type RuleBasedAudience,
} from "features/mediaDataRoom/models";

export interface LookalikeAdvertiserAudienceGeneratorContextValue {
  isAdvertiserAudienceQualityDrawerOpen: boolean;
  openAdvertiserAudienceQualityDrawer: () => void;
  closeAdvertiserAudienceQualityDrawer: () => void;
  reach: number;
  setReach: (reach: SetStateAction<number>) => void;
  excludeSeedAudience: boolean;
  setExcludeSeedAudience: (
    excludeSeedAudience: SetStateAction<boolean>
  ) => void;
  audienceName: string;
  setAudienceName: (name: SetStateAction<string>) => void;
  selectedSeedAudience: RuleBasedAudience | AdvertiserAudience | null;
  setSelectedSeedAudience: (
    audience: SetStateAction<RuleBasedAudience | AdvertiserAudience | null>
  ) => void;
  generationStep: "pending" | "completed" | null;
  generateAudience: () => void;
  showExcludeSeedAudienceCheckbox: boolean;
  availableSeedAudiences: MediaDataRoomJobHookResult<
    (RuleBasedAudience | AdvertiserAudience)[]
  >;
  selectedAudienceStatistics: LookalikeAudienceStatistics | null;
}

export const LookalikeAdvertiserAudienceGeneratorContext =
  createContext<LookalikeAdvertiserAudienceGeneratorContextValue>({
    audienceName: "",
    availableSeedAudiences: {
      computeResults: [],
      error: undefined,
      loading: false,
      retry: async () => {},
      setCacheData: () => {},
      status: "COMPLETED",
    },
    closeAdvertiserAudienceQualityDrawer: () => {},
    excludeSeedAudience: false,
    generateAudience: () => {},
    generationStep: null,
    isAdvertiserAudienceQualityDrawerOpen: false,
    openAdvertiserAudienceQualityDrawer: () => {},
    reach: 1,
    selectedAudienceStatistics: null,
    selectedSeedAudience: null,
    setAudienceName: () => {},
    setExcludeSeedAudience: () => {},
    setReach: () => {},
    setSelectedSeedAudience: () => {},
    showExcludeSeedAudienceCheckbox: false,
  });

export const LookalikeAdvertiserAudienceGeneratorProvider =
  LookalikeAdvertiserAudienceGeneratorContext.Provider;

export const useLookalikeAdvertiserAudienceGenerator = () =>
  useContext(LookalikeAdvertiserAudienceGeneratorContext);
