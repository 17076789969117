import { DqCard, DqCardContent, DqLoader } from "@decentriq/components";
import {
  type DatasetByHashQuery,
  DataSourceType,
} from "@decentriq/graphql/dist/types";
import {
  faClock,
  faCopy,
  faDatabase,
  faFileLines,
  faGlobe,
  faHardDrive,
  faHashtagLock,
  faInputText,
  faTable,
  faUser,
  faWarning,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemContent,
  ListItemDecorator,
  Stack,
  Tooltip,
  Typography,
} from "@mui/joy";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { Fragment, memo, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { DetailsGridValue } from "components";
import {
  dataSourceTypePresentation,
  DeprovisionDatasetButton,
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
  formatSize,
} from "features/datasets";
import {
  useDatasetDetailsConfig,
  useDatasetPermission,
} from "features/datasets/contexts";
import { useCopyToClipboard } from "hooks";
import { type DataRoomTypeNames, dataRoomTypePrefix } from "models";
import { type DatasetSchema } from "utils/dataset";

interface DatasetInfoTabPanelProps {
  manifestHash: string;
  dataset: DatasetByHashQuery["datasetByManifestHash"] | undefined;
  loading: boolean;
  schema: DatasetSchema | undefined;
}

const DatasetInfoTabPanel = memo<DatasetInfoTabPanelProps>(
  ({ manifestHash, dataset, schema, loading }) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [, copyToClipboard] = useCopyToClipboard();
    const hasSchema = useMemo(() => Boolean(schema), [schema]);
    const { disableDeprovision, disableProvisionedDataRoomNavigation } =
      useDatasetDetailsConfig();
    const {
      canViewDatasetImportDetailsOrDefault,
      canDeprovisionDatasetOrDefault,
    } = useDatasetPermission();
    const canViewDatasetImportDetails = useMemo(
      () => canViewDatasetImportDetailsOrDefault(dataset?.isUploader),
      [dataset?.isUploader, canViewDatasetImportDetailsOrDefault]
    );
    const canDeprovisionDataset = useMemo(
      () => canDeprovisionDatasetOrDefault(dataset?.isUploader),
      [dataset?.isUploader, canDeprovisionDatasetOrDefault]
    );
    if (!dataset || loading) {
      return (
        <Stack alignItems="center" padding={3}>
          <DqLoader />
        </Stack>
      );
    }
    const publications = dataset.publications?.nodes || [];
    const isTabular = hasSchema;
    return (
      <Stack>
        {!!dataset.description && (
          <DqCard>
            <DqCardContent sx={{ alignItems: "flex-start" }}>
              <Typography
                gutterBottom={true}
                level="body-sm"
                textColor="inherit"
              >
                <strong>Description</strong>
              </Typography>
              <DetailsGridValue
                icon={faInputText}
                value={dataset.description}
              />
            </DqCardContent>
          </DqCard>
        )}
        <DqCard>
          <DqCardContent sx={{ alignItems: "flex-start" }}>
            <Typography gutterBottom={true} level="body-sm" textColor="inherit">
              <strong>Uploaded by</strong>
            </Typography>
            <DetailsGridValue icon={faUser} value={dataset.owner.email} />
          </DqCardContent>
        </DqCard>
        <DqCard>
          <DqCardContent sx={{ alignItems: "flex-start" }}>
            <Typography gutterBottom={true} level="body-sm" textColor="inherit">
              <strong>Upload date</strong>
            </Typography>
            <DetailsGridValue
              icon={faClock}
              value={format(
                new Date(dataset?.createdAt),
                "yyyy-MM-dd HH:mm:ss"
              )}
            />
          </DqCardContent>
        </DqCard>
        {canViewDatasetImportDetails && (
          <DqCard>
            <DqCardContent sx={{ alignItems: "flex-start" }}>
              <Typography
                gutterBottom={true}
                level="body-sm"
                textColor="inherit"
              >
                <strong>Imported from</strong>
              </Typography>
              <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
                <ExternalConnectionsIcon
                  connectionType={dataset?.sourceType}
                  size={ExternalConnectionsIconSize.xs}
                />
                <Typography level="body-sm">
                  {dataSourceTypePresentation[dataset?.sourceType]}
                </Typography>
              </Box>
              {dataset.sourceType === DataSourceType.S3 &&
                dataset.datasetImport?.config && (
                  <Fragment>
                    <Grid sm={6} xs={12}>
                      <Typography
                        component="div"
                        gutterBottom={true}
                        level="body-sm"
                      >
                        <strong>Bucket</strong>
                      </Typography>
                      <DetailsGridValue
                        icon={faDatabase}
                        value={dataset.datasetImport?.config.bucket}
                      />
                    </Grid>
                    <Grid sm={6} xs={12}>
                      <Typography
                        component="div"
                        gutterBottom={true}
                        level="body-sm"
                      >
                        <strong>Region</strong>
                      </Typography>
                      <DetailsGridValue
                        icon={faGlobe}
                        value={dataset?.datasetImport?.config?.region}
                      />
                    </Grid>
                  </Fragment>
                )}
            </DqCardContent>
          </DqCard>
        )}
        <DqCard>
          <DqCardContent sx={{ alignItems: "flex-start" }}>
            <Typography gutterBottom={true} level="body-sm" textColor="inherit">
              <strong>Type</strong>
            </Typography>
            <DetailsGridValue
              icon={isTabular ? faTable : faFileLines}
              value={isTabular ? "Tabular" : "File"}
            />
          </DqCardContent>
        </DqCard>
        <DqCard>
          <DqCardContent sx={{ alignItems: "flex-start" }}>
            <Typography gutterBottom={true} level="body-sm" textColor="inherit">
              <strong>Dataset ID</strong>
            </Typography>
            {/* This thing throws error cause its uses div inside p */}
            <DetailsGridValue
              icon={faHashtagLock}
              iconPosition="right"
              value={
                <Stack
                  alignContent="center"
                  alignItems="center"
                  direction="row"
                >
                  <Tooltip placement="top" title={manifestHash}>
                    <Typography level="body-sm" noWrap={true}>
                      {manifestHash}
                    </Typography>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title={<span>Copy dataset hash</span>}
                  >
                    <IconButton
                      color="neutral"
                      onClick={() => {
                        if (manifestHash) {
                          copyToClipboard(manifestHash);
                          enqueueSnackbar("Dataset hash copied");
                        }
                      }}
                      size="sm"
                      type="button"
                      variant="plain"
                    >
                      <FontAwesomeIcon fixedWidth={true} icon={faCopy} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />
          </DqCardContent>
        </DqCard>
        {!!dataset.size && (
          <DqCard>
            <DqCardContent sx={{ alignItems: "flex-start" }}>
              <Typography
                gutterBottom={true}
                level="body-sm"
                textColor="inherit"
              >
                <strong>File size</strong>
              </Typography>
              <DetailsGridValue
                icon={faHardDrive}
                value={formatSize(dataset.size)}
              />
            </DqCardContent>
          </DqCard>
        )}
        <DqCard>
          <DqCardContent sx={{ alignItems: "flex-start" }}>
            <Typography gutterBottom={true} level="body-sm" textColor="inherit">
              <strong>Provisioned to</strong>
            </Typography>
            {publications.length ? (
              <List sx={{ gap: 0.5, p: 0 }}>
                {publications.map(({ dataRoom, leaf }) => (
                  <ListItem sx={{ p: 0 }}>
                    {!disableDeprovision && canDeprovisionDataset ? (
                      <ListItemDecorator
                        key={dataRoom.id}
                        onClick={
                          disableProvisionedDataRoomNavigation
                            ? undefined
                            : () =>
                                navigate(
                                  `/datarooms/${
                                    dataRoomTypePrefix[
                                      dataRoom.__typename as DataRoomTypeNames
                                    ]
                                  }/${dataRoom.id}`
                                )
                        }
                        sx={{
                          marginRight: disableDeprovision ? "8px" : 0,
                          width: "fit-content",
                        }}
                      >
                        <DeprovisionDatasetButton
                          dataRoomId={dataRoom.id}
                          datasetId={dataset.id}
                          driverAttestationHash={dataRoom.driverAttestationHash}
                          leaf={leaf}
                        />
                      </ListItemDecorator>
                    ) : null}
                    <ListItemContent>{dataRoom.title}</ListItemContent>
                  </ListItem>
                ))}
              </List>
            ) : (
              <DetailsGridValue
                icon={faWarning}
                value="This dataset is not provisioned to any data clean room"
              />
            )}
          </DqCardContent>
        </DqCard>
      </Stack>
    );
  }
);

DatasetInfoTabPanel.displayName = "DatasetInfoTabPanel";

export default DatasetInfoTabPanel;
