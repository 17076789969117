import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CircularProgress, Typography } from "@mui/joy";
import { memo, useCallback, useState } from "react";
import { DataNodeInvalidRowsInfo } from "features/dataNodes";
import {
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom/contexts";
import { type LegacyValidationReport } from "features/mediaDataRoom/models";

const pluralize = (number: number): string =>
  number === 1 ? "entry" : "entries";

const AdvertiserDataReportLabel = memo(() => {
  const { advertiserDataReport } = useMediaDataRoomInsightsData();
  const {
    supportedFeatures: { enableDropInvalidRows },
  } = useMediaDataRoom();
  const [retryInProgress, setRetryInProgress] = useState(false);
  const retry: (event: React.MouseEvent<HTMLAnchorElement>) => Promise<void> =
    useCallback(
      async (event) => {
        event.stopPropagation();
        setRetryInProgress(true);
        try {
          await advertiserDataReport.retry();
        } finally {
          setRetryInProgress(false);
        }
      },
      [advertiserDataReport]
    );
  if (advertiserDataReport.error) {
    return (
      <>
        <FontAwesomeIcon
          fixedWidth={true}
          icon={faTriangleExclamation}
          size="2x"
        />
        <Typography>Failed to fetch data report</Typography>
        <Button loading={retryInProgress} onClick={retry} variant="plain">
          Retry
        </Button>
      </>
    );
  }
  if (advertiserDataReport.status !== "COMPLETED") {
    return (
      <Typography
        endDecorator={
          <CircularProgress
            sx={{ "--CircularProgress-size": "12px", marginLeft: "6px" }}
          />
        }
      >
        Checking for duplicates in the data...
      </Typography>
    );
  }
  const validationReport = advertiserDataReport.computeResults;
  if (!validationReport?.report) {
    return <Typography>Data report is missing</Typography>;
  }
  // Handle both v1 and v2 MDCR cases if report is present
  if (
    validationReport.version === "legacy"
      ? validationReport.report.num_deduplicated_rows === 0
      : validationReport.report.dropInvalidRows?.numInvalidRowsDropped === 0
  ) {
    return <Typography>No duplicates found in the data</Typography>;
  }
  if (!enableDropInvalidRows || validationReport.version === "legacy") {
    const report =
      validationReport.report as unknown as LegacyValidationReport["report"];
    return (
      <Typography>{`Dropped ${report.num_deduplicated_rows} duplicate ${pluralize(
        validationReport.report.num_deduplicated_rows as number
      )}. There are ${report.num_ingested_rows} remaining ${pluralize(
        validationReport.report.num_ingested_rows as number
      )}.`}</Typography>
    );
  }
  return (
    <DataNodeInvalidRowsInfo
      datasetName="Advertiser audience"
      validationReport={validationReport}
    />
  );
});

export default AdvertiserDataReportLabel;
