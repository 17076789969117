import { Box, styled } from "@mui/joy";
import { SIDEBAR_WIDTH } from "features/sidebar";

const AudienceGeneratorStepActionsWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutral[50],
  bottom: 0,
  display: "flex",
  justifyContent: "flex-end",
  left: `${SIDEBAR_WIDTH}px`,
  padding: theme.spacing(1, 2),
  position: "fixed",
  right: 0,
}));

export default AudienceGeneratorStepActionsWrapper;
