import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Option,
  Select,
  Stack,
} from "@mui/joy";
import { memo } from "react";
import {
  type AdvertiserAudience,
  type RuleBasedAudience,
  useMediaDataRoom,
} from "features/mediaDataRoom";
import {
  AddressableAudience,
  SeedAudience,
} from "features/mediaDataRoom/components";
import {
  AdvertiserAudienceGeneratorHeader,
  AdvertiserAudienceGeneratorStep,
  AudienceGeneratorStepActionsWrapper,
  AudienceGeneratorStepSectionDescription,
  AudienceGeneratorStepSectionTitle,
  useAdvertiserAudienceGenerator,
} from "features/mediaDataRoom/containers/AdvertiserAudienceGenerator";
import { useLookalikeAdvertiserAudienceGenerator } from "../../contexts/LookalikeAdvertiserAudienceGeneratorContext";
import {
  AudienceGeneratorReachSlider,
  AudienceQualityStatistics,
  ExcludeSeedAudienceCheckbox,
} from "..";

const LookalikeAdvertiserAudienceGeneratorConfigurationStep = memo(() => {
  const {
    selectedSeedAudience,
    setSelectedSeedAudience,
    availableSeedAudiences: { computeResults: seedAudiences = [] },
    selectedAudienceStatistics,
    showExcludeSeedAudienceCheckbox,
  } = useLookalikeAdvertiserAudienceGenerator();
  const {
    addressableAudienceSize,
    seedAudienceSize,
    computeState: { loading: isStatisticsComputationLoading },
  } = selectedAudienceStatistics!;
  const {
    supportedFeatures: { showAbsoluteValues },
  } = useMediaDataRoom();
  const { handleBackStep, handleNextStep, isFirstStep, cancelGeneration } =
    useAdvertiserAudienceGenerator();
  const shouldCancelGenerationOnBack = isFirstStep(
    AdvertiserAudienceGeneratorStep.CONFIGURATION
  );
  return (
    <>
      <Grid container={true} sx={{ height: "100%" }}>
        <Grid xs={7}>
          <Box mb={3}>
            <AdvertiserAudienceGeneratorHeader />
          </Box>
          <Stack direction="column" spacing={2}>
            <Box>
              <AudienceGeneratorStepSectionTitle>
                1. AI Lookalike audience setup
              </AudienceGeneratorStepSectionTitle>
              <AudienceGeneratorStepSectionDescription>
                Choose the audience that will be used to seed your lookalike
                model.
              </AudienceGeneratorStepSectionDescription>
            </Box>
            <FormControl>
              <FormLabel>Advertiser audience</FormLabel>
              <Select
                onChange={(event, value) => {
                  const selectedSeedAudience = seedAudiences.find(
                    ({ id }) => id === value
                  );
                  setSelectedSeedAudience(
                    selectedSeedAudience as
                      | RuleBasedAudience
                      | AdvertiserAudience
                  );
                }}
                value={selectedSeedAudience?.id || ""}
              >
                {seedAudiences.map(({ id, mutable: { name } }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </FormControl>
            {showAbsoluteValues && (
              <Stack direction="row" spacing={2}>
                <AddressableAudience
                  addressableAudience={addressableAudienceSize}
                  loading={isStatisticsComputationLoading}
                />
                <SeedAudience
                  loading={isStatisticsComputationLoading}
                  seedAudience={seedAudienceSize}
                />
              </Stack>
            )}
          </Stack>
          <Stack direction="column" mt={4} spacing={3}>
            <Box>
              <AudienceGeneratorStepSectionTitle>
                2. Define the Reach
              </AudienceGeneratorStepSectionTitle>
              <AudienceGeneratorStepSectionDescription>
                Set the precision and reach for your lookalike model
              </AudienceGeneratorStepSectionDescription>
            </Box>
            <AudienceGeneratorReachSlider />
            {showExcludeSeedAudienceCheckbox && <ExcludeSeedAudienceCheckbox />}
          </Stack>
        </Grid>
        <Grid display="flex" justifyContent="center" xs={1}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid container={true} xs={4}>
          <AudienceQualityStatistics />
        </Grid>
      </Grid>
      <AudienceGeneratorStepActionsWrapper>
        <Button
          onClick={
            shouldCancelGenerationOnBack ? cancelGeneration : handleBackStep
          }
          sx={{ mr: 1 }}
        >
          {shouldCancelGenerationOnBack ? "Cancel" : "Back"}
        </Button>
        <Button
          color="primary"
          disabled={!selectedSeedAudience?.id}
          onClick={handleNextStep}
          variant="solid"
        >
          Continue
        </Button>
      </AudienceGeneratorStepActionsWrapper>
    </>
  );
});

LookalikeAdvertiserAudienceGeneratorConfigurationStep.displayName =
  "LookalikeAdvertiserAudienceGeneratorConfigurationStep";

export default LookalikeAdvertiserAudienceGeneratorConfigurationStep;
