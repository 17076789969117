import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Tooltip, Typography } from "@mui/joy";
import { saveAs } from "file-saver";
import { memo, useCallback } from "react";
import { type ValidationReport } from "validate-js";

interface DataNodeInvalidRowsInfoProps {
  validationReport: ValidationReport;
  datasetName: string;
  iconOnly?: boolean;
}

const DataNodeInvalidRowsInfo = memo<DataNodeInvalidRowsInfoProps>(
  ({ validationReport, datasetName, iconOnly = false }) => {
    const { dropInvalidRows, numRowsTotal } = validationReport.report;
    const handleValidationReportDownload = useCallback(() => {
      const datasetValidationReportBlob = new Blob(
        [JSON.stringify(validationReport.report, null, 2)],
        {
          type: "application/json",
        }
      );
      saveAs(
        datasetValidationReportBlob,
        `${datasetName} - validation report.json`
      );
    }, [validationReport.report, datasetName]);
    return dropInvalidRows?.numInvalidRowsDropped ? (
      <Tooltip
        arrow={true}
        color="warning"
        onClick={(event) => event.stopPropagation()}
        title={
          <Typography
            level="body-sm"
            sx={({ palette }) => ({ color: palette.common.white })}
          >
            Out of a total of {numRowsTotal} rows in the <b>{datasetName}</b>{" "}
            dataset, {dropInvalidRows?.numInvalidRowsDropped} invalid rows have
            been dropped. For details, please{" "}
            <u
              onClick={handleValidationReportDownload}
              style={{ cursor: "pointer" }}
            >
              download the report.
            </u>
          </Typography>
        }
      >
        <Typography color="warning">
          <Stack alignItems="center" direction="row">
            <FontAwesomeIcon icon={faTriangleExclamation} />
            {!iconOnly
              ? `${dropInvalidRows?.numInvalidRowsDropped} invalid rows have been
            dropped`
              : null}
          </Stack>
        </Typography>
      </Tooltip>
    ) : null;
  }
);

DataNodeInvalidRowsInfo.displayName = "DataNodeInvalidRowsInfo";

export default DataNodeInvalidRowsInfo;
